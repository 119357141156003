import apiv2 from '@/api';

export const state = () => ({
    isLoading: false,
    devices: [],
    availableDevices: []
});

export const mutations = {
    SET_DEVICES (state, devices) {
        state.devices = devices
    },
    SET_AVAILABLE_DEVICES (state, availableDevices) {
        state.availableDevices = availableDevices || []
    },
    SET_LOADING (state, loading) {
        state.isLoading = loading
    }
}

export const actions = {
    async getDevices ({ commit }, { profileId }) {
        commit('SET_LOADING', true)
        const response = await apiv2.getProfileDevices(profileId)
        commit('SET_DEVICES', response)
        commit('SET_LOADING', false)
    },
    async getAvailableDevices ({ commit }) {
        const response = await apiv2.getAvailableDevices()
        commit('SET_AVAILABLE_DEVICES', response)
    },
    async submitOrder ({ dispatch }, { profileId, order }) {
        await apiv2.submitDeviceOrder(profileId, order)
        await dispatch('getDevices', { profileId })
    },
    async cancelOrder ({ dispatch }, { profileId, orderId }) {
        try {
            await apiv2.cancelDeviceOrder(orderId)
        } catch (e) {
            throw e
        }
        finally {
            await dispatch('getDevices', { profileId })
        }
    }
}

export const getters = {
    sortedLatestDevices: (s) => [...s.devices].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)),
    devicesCount: (s) => s.devices.length || 0
}

