import {
  ACTION_LABEL_MAP,
  ACTION_TYPE_USER_ADD,
  ACTION_TYPE_USER_EDIT,
  ACTIONS_VISIBLE_IN_PERMISSIONS
} from "@/PortalConstants";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIMEZONE,
  formatDate,
  formatTime,
  formatDateTime
} from '../utils/dateUtil';
import {store} from "@/store";
import gatewayUrlBuilder from '@/lib/GatewayUrlBuilder';

export default {
  methods: {
    /**
     * Reset the logout timeout, in seconds, from now.
     *
     * @param seconds Integer of number of seconds from now to reset the logout to.
     */
    setLogoutTimeout(seconds) {
      // Note: I figured out this method by reading the code in node_modules/@nuxtjs/auth-next/dist/runtime.mjs
      const now = new Date().getTime();
      const expiration = now + (seconds * 1000);

      // this.$auth.$storage.setUniversal('_token_expiration.local', expiration);

      this.commit('portal', 'setSessionExpirationDate', new Date(expiration));
    },
    /**
     * The handler for the "extend session" button in the auto-logout bar at the top of the screen
     * when a user is about to be logged out.
     */
    extendSession() {
      const { site } = store.state.portal;
      let seconds = 20 * 60 * 1000; // Default is to extend by 20 minutes

      // But if we have a site with a portal_session_timeout_s we use that.
      if (site && site.feature_values.portal_session_timeout_s) {
        seconds = parseInt(site.feature_values.portal_session_timeout_s);
      }

      this.setLogoutTimeout(Math.max(seconds, 60 * 3));
    },
    /**
     * Return a store property ensuring no thrown errors and with ability to set a default.
     * @param namespace The Next.js Vuex namespace/store name. E.g. "timeline" or "overwriting".
     * @param keysDotDelimited A dot-delimited key within the store to access.
     * @param def Default value.
     * @returns {undefined|*}
     */
    getStorePropertySafe(namespace, keysDotDelimited, def = undefined) {
      let args = keysDotDelimited.split(".");
      let v = store.state[namespace];
      while (args.length) {
        try {
          const field = args.shift();
          v = v[field];
        } catch (error) {
          return def;
        }
      }
      return v;
    },
    /**
     * Return site user objects for currently logged in user within a site. This should return an
     * array with only one item. TODO probably a better and more sane way to do this.
     *
     * @param site The site to find SiteUser objects within.
     * @returns {*|*[]}
     */
    getMyUsersForSite(site) {
      if (!site) return this.myUsers || [];

      return this.myUsers ? this.myUsers.filter(u => u.site_users.find(su => su.site_id === site.id)) : [];
    },
    /**
     * Get a role object by its id.
     *
     * @param id
     * @returns {unknown}
     */
    getRoleById(id) {
      // Note 1) Some roles that are included with a site_user are not visible in the portal, so those
      // will need to be filtered out when calling getRoleById.
      return this.settings.roles.find(r => r.id === id);
    },
    /**
     * If the site has been loaded, this returns the site object by its id. Note this will NOT make a call
     * to the server and assumes it has already been made.
     *
     * @param id The site id.
     * @returns {*}
     */
    getSiteById(id) {
      // Note 2) Some sites that the user is a part of might not be visible to the currently logged in user
      // and we need to filter those out.
      return this.sites.find(s => s.id === id);
    },
    /**
     * Given a user object, this returns the sites for that user. Does not make a server call.
     *
     * @param user
     * @returns {*[]}
     */
    getSitesForUser(user) {
      if (!user) return [];

      return user.site_users.map(su => this.getSiteById(su.site_id));
    },
    /**
     * Get my own single site user object for a specified site.
     *
     * @param site A site object, expected to have a key id.
     * @returns {undefined|*}
     */
    getMySiteUserForSite(site) {
      if(site) {
        const siteUsers = this.getStorePropertySafe("auth", "user.site_users");
        if (siteUsers) {
          return siteUsers.find(su => su.site_user.site_id === site.id).site_user;
        }
        return undefined;
      }
    },
    /**
     * Get site user objects for a User object, and populates them with a 'site' and 'roles' and 'siteUser' keys.
     *
     * @param user
     * @returns {T[]|*[]}
     */
    getSiteUsersPopulatedForUser(user) {
      return user
        ? user.site_users
            .map(siteUser => ({
              site: this.getSiteById(siteUser.site_id),
              roles: siteUser.roles.map(r => this.getRoleById(r.id)).filter(r => !!r), // See Note (1) above
              siteUser
            }))
            .filter(populated => !!populated.site) // See Note (2) above
        : [];
    },
    /**
     * Shorthand method to commit a mutation to the Vuex store.
     *
     * @param namespace The Vuex namespace/store name. E.g. 'timeline' or 'overwriting'.
     * @param mutation The mutation name.
     * @param values The values to pass to the mutation.
     */
    commit(namespace, mutation, ...values) {
      store.commit(namespace + "/" + mutation, ...values);
    },
    /**
     * Shorthand method to dispatch a Vuex action.
     *
     * @param namespace The Vuex namespace/store name. E.g. 'timeline' or 'overwriting'.
     * @param mutation The action name.
     * @param values The values to pass to the action.
     */
    dispatch(namespace, action, ...values) {
      return store.dispatch(namespace + "/" + action, ...values);
    },
    /**
     * Gets the permissible actions for the currently logged in user within the provided site.
     *
     * @param site
     * @returns {*[]}
     */
    myPermissibleActionsInSite(site) {
      const globalActions = this.getStorePropertySafe(
        "portal",
        "settings.global_configuration.site_user_actions_permitted"
      );
      const siteUser = this.getMySiteUserForSite(site);

      if (globalActions && siteUser) {
        const actionsInSite = siteUser.roles.reduce((acc, role) => acc.concat(role.actions_permitted || []), []);

        // Note this will probably contain duplicates at some point
        return [...globalActions, ...actionsInSite];
      }

      return [];
    },
    /**
     * Returns the actions that can be performed by the authenticated user within a site, but filtered to only
     * the ones that are displayable within the Portal.
     *
     * @param site
     * @returns {*[]}
     */
    myVisiblePermissibleActionsInSite(site) {
      const actionsPermitted = this.myPermissibleActionsInSite(site);

      const visibleActions = actionsPermitted.filter(
        actionPermitted => ACTIONS_VISIBLE_IN_PERMISSIONS.indexOf(actionPermitted) !== -1
      );

      return visibleActions.map(visibleAction => ACTION_LABEL_MAP[visibleAction]);
    },
    /**
     * True if the authenticated user can add users to any sites.
     *
     * @returns {boolean}
     */
    userCanAddNewUser() {
      return this.hasPermissionInAnySite(ACTION_TYPE_USER_ADD);
    },
    /**
     * True if the authenticated user can edit users within any sites.
     *
     * @returns {boolean}
     */
    userCanEditUser(user) {
      return this.hasPermissionForUser(user, ACTION_TYPE_USER_EDIT);
    },
    /**
     * Returns true if the authenticated has permission within any site to perform the provided action.
     *
     * @param action
     * @returns {boolean}
     */
    hasPermissionInAnySite(action) {
      const sites = this.getStorePropertySafe("portal", "sites", []);
      const allActionsPermittedInAllSites = sites.reduce(
        (acc, site) => acc.concat(this.myPermissibleActionsInSite(site.site)),
        []
      );
      return allActionsPermittedInAllSites.indexOf(action) !== -1;
    },
    /**
     * True if the provided user has permission to perform the provided action in any sites of which they
     * are a part.
     *
     * @param user
     * @param action
     * @returns {boolean}
     */
    hasPermissionForUser(user, action) {
      const sitesForUser = (user.site_users || []).map(su => this.getSiteById(su.site_id));
      let hasPermission = false;

      sitesForUser.forEach(site => (hasPermission = hasPermission || this.hasPermissionInSite(site, action)));

      return hasPermission;
    },
    /**
     * Given a site or siteID, returns true if the authenticatd user has permission to perform the provided
     * action. TODO: it feels like these perms methods need better organization.
     *
     * @param siteOrSiteId
     * @param action
     * @returns {boolean}
     */
    hasPermissionInSite(siteOrSiteId, action) {
      if (!siteOrSiteId) return false;
      if (typeof siteOrSiteId === "string") {
        siteOrSiteId = this.getSiteById(siteOrSiteId);
      }
      return this.myPermissibleActionsInSite(siteOrSiteId).indexOf(action) !== -1;
    },
    /**
     * Get all actions for the authenticated user. TODO: not sure what this is supposed to be for -jjung.
     *
     * @returns {*[]}
     */
    getGlobalActions() {
      let actions = [];
      let roles = this.getStorePropertySafe("portal", "settings.roles", []);

      roles.forEach(role => (actions = actions.concat(role.actions_permitted)));

      return actions;
    },
    /**
     * Returns the role object for Portal Admin role and its default permissions.
     *
     * @returns {*}
     */
    getPortalAdminRole() {
      return this.getStorePropertySafe("portal", "settings.roles", []).find(r => r.code === "portal_site_admin");
    },
    /**
     * Returns the role object for Portal Caregiver role and its default permissions.
     *
     * @returns {*}
     */
    getPortalCaregiverRole() {
      return this.getStorePropertySafe("portal", "settings.roles", []).find(r => r.code === "portal_site_caregiver");
    },
    /**
     * Returns true if the authenticated user has the admin role within the provided site.
     *
     * @param site
     * @returns {boolean}
     */
    isAdminInSite(site) {
      return !!this.getMySiteUserForSite(site).roles.find(role => role.code === "portal_site_admin");
    },
     /**
     * Returns true if the authenticated user has the caregiver role within the provided site.
     *
     * @param site
     * @returns {boolean}
     */
    isCaregiverInSite(site) {
      return !!this.getMySiteUserForSite(site).roles.find(role => role.code === "portal_site_caregiver");
    },
    /**
     * Returns all the sites which the authenticated user has permission to perform the provided action.
     * @param action
     * @returns {*}
     */
    sitesWithPermission(action) {
      return this.sites.filter(site => this.hasPermissionInSite(site, action));
    },
    /**
     * Returns all the sites which the authenticated user DOES NOT have permission to perform the provided action.
     * @param action
     * @returns {*}
     */
    sitesWithoutPermission(action) {
      return this.sites.filter(site => !this.hasPermissionInSite(site, action));
    },
    /**
     * Push a success message to the snackbar.
     *
     * @param successMessage A string of the success message to push.
     * @returns {*}
     */
    pushSuccessMessage(successMessage) {
      this.commit("snackbar", "success", {
        message: successMessage
      });
    },
    /**
     * Sets the currently active site, and pulls in the associated feature values for units and date/time and applies
     * them across the entire application.
     *
     * @param successMessage A string of the success message to push.
     * @returns {*}
     */
    setSite(site) {
      this.site = site;

      this.commit('portal', 'setSite', site);

      if (this.site) {
        this.commit('unitsAndFormats', 'setWeightUnit', site.feature_values.unit_weight);
        this.commit('unitsAndFormats', 'setHeightUnit', site.feature_values.unit_height);
        this.commit('unitsAndFormats', 'setTemperatureUnit', site.feature_values.units_temperature);
      }
    },
    //---------------------------------------------------------------------------------
    // Units and Formats
    //---------------------------------------------------------------------------------
    formatDate(dateOrString, formatOverride = undefined, ianaTimezoneOverride = undefined) {
      if (!dateOrString) {
        return 'N/A';
      }

      let format = DEFAULT_DATE_FORMAT;

      if (formatOverride) {
        format = formatOverride;
      } else if (store && store.state.unitsAndFormats && store.state.unitsAndFormats.dateFormat) {
        format = store.state.unitsAndFormats.dateFormat;
      }

      return formatDate(dateOrString, format, ianaTimezoneOverride || DEFAULT_TIMEZONE);
    },
    formatDateTime(dateOrString, formatOverride = undefined, ianaTimezoneOverride = undefined) {
      if (!dateOrString) {
        return 'N/A';
      }

      let format = DEFAULT_DATE_TIME_FORMAT;

      if (formatOverride) {
        format = formatOverride;
      } else if (store && store.state.unitsAndFormats && store.state.unitsAndFormats.dateTimeFormat) {
        format = store.state.unitsAndFormats.dateTimeFormat;
      }

      return formatDateTime(dateOrString, format, ianaTimezoneOverride || DEFAULT_TIMEZONE);
    },
    formatTime(dateOrString, formatOverride = undefined, ianaTimezoneOverride = undefined) {
      if (!dateOrString) {
        return 'N/A';
      }

      let format = DEFAULT_TIME_FORMAT;

      if (formatOverride) {
        format = formatOverride;
      } else if (store && store.state.unitsAndFormats && store.state.unitsAndFormats.timeFormat) {
        format = store.state.unitsAndFormats.timeFormat;
      }
      return formatTime(dateOrString, format, ianaTimezoneOverride || DEFAULT_TIMEZONE);
    },
    formatWeight(weightInKg) {
      if (store.state.unitsAndFormats.weightUnit === 'lbs') {
        return Math.round(weightInKg * 2.20462) + ' lbs';
      }

      return Math.round(weightInKg) + ' kg';
    },
    formatHeight(heightInCm) {
      if (store.state.unitsAndFormats.heightUnit === 'in') {
        function toFeet(n) {
          const realFeet = ((n*0.393700) / 12);
          let feet = Math.floor(realFeet);
          let inches = Math.round((realFeet - feet) * 12);

          // Fix for CP-343
          if (inches === 12) {
            feet++;
            inches = 0;
          }

          return feet + "' " + inches + '"';
        }

        return toFeet(heightInCm);
      }

      return heightInCm;
    },
    formatTemperature(temperatureInCelsius) {
      return temperatureInCelsius;
    },
    gatewayProfileDetailsUrl(profileId) {
      return gatewayUrlBuilder.profileDetails(profileId);
    },
    gatewayAddProfileUrl(siteId) {
      return gatewayUrlBuilder.addProfile(siteId);
    }
  },
  computed: {
    me() {
      return this.getStorePropertySafe('auth', 'user', undefined);
    },
    isStaff() {
      return this.me ? this.me.is_staff : false;
    },
    curSiteUser() {
      return this.getMySiteUserForSite(this.getStorePropertySafe('portal', 'site', undefined));
    },
    debugEnabled() {
      return this.me ? this.me.is_nuvoair_employee && this.$route.query.debug : false;
    },
    sites() {
      return this.getStorePropertySafe("portal", "sites", []);
    },
    myUsers() {
      return this.getStorePropertySafe("portal", "myUsers", []).map(o => o.user);
    },
    settings() {
      return this.getStorePropertySafe("portal", "settings", {});
    },
    isSettingsRoute() {
      return this.$route.path.startsWith("/settings");
    },
    isMyPermissionsRoute() {
      return this.$route.path.startsWith("/settings/my-permissions");
    },
    overwritingEnabled() {
      const { site } = store.state.portal;

      return site && site.feature_values && site.feature_values.portal_overwriting_enabled.toLowerCase() === "true";
    },
    asthmaReportEnabled() {
      const { site } = store.state.portal;

      return site && site.feature_values && site.feature_values.portal_asthma_report_enabled.toLowerCase() === "true";
    },
    spo2Enabled() {
      const { site } = store.state.portal;
      
      return site && site.feature_values && this.site.feature_values.portal_spo2 === 'true';
    },
    dyspneaEnabled() {
      const { site } = store.state.portal;
      
      return site && site.feature_values && this.site.feature_values.portal_dyspnea === 'true';
    },
    isCopdSite() {
      const { site } = store.state.portal;
      return site && this.site.conditions.find(condition =>  condition.name.toLowerCase() === 'copd')
    },
    isUSFeature () {
      const { site } = store.state.portal;
      return site && site.feature_values && site.feature_values.portal_us_features === 'true'
    },
    zoomEnabledForAnySite() {
      return this.sites.some(site => site.feature_values && site.feature_values.zoom_smart_integration_feature === 'true');
    },
    zoomEnabledForThisSite() {
      const { site } = store.state.portal;
      return site && site.feature_values && site.feature_values.zoom_smart_integration_feature === 'true';
    },
    manualUrl() {
      const { site } = store.state.portal;

      return site && site.feature_values ? site.feature_values.portal_manual_url : undefined;
    },
    isInternalUser () {
      return (this.curSiteUser && this.curSiteUser.roles.filter(e => ['portal_site_admin', 'admin', 'super_admin'].includes(e.code)).length > 0)
    },
  },
  watch: {
    "$store.state.unitsAndFormats": {
      deep: true,
      handler: function(val) {}
    }
  }
};
