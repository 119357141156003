import moment from 'moment';
import apiv2 from '@/api';
export const state = () => ({
  loading: false,
  lastUpdatedDate: null,
  appointments: [],
  totalRecords: 0
});

export const mutations = {
  SET_LOADING (state, loading) {
    state.loading = loading;
  },
  SET_TOTAL_RECORDS (state, totalRecords) {
    state.totalRecords = totalRecords;
  },
  SET_APPOINTMENTS (state, appointments) {
    state.appointments = appointments;
    state.lastUpdatedDate = moment();
  },
  ADD_APPOINTMENT (state, appointment) {
    state.appointments.push(appointment);
  },
  UPDATE_APPOINTMENT (state, updatedAppointment) {
    const appointmentIndex = state.appointments.findIndex(appointment => appointment.id === updatedAppointment.id);
    if (appointmentIndex !== -1) {
      state.appointments.splice(appointmentIndex, 1, updatedAppointment);
    }
  },
  ADD_APPOINTMENT_NOTE (state, newNote) {
    const appointmentIndex = state.appointments.findIndex(appointment => appointment.id === newNote.appointment.id);
    if (appointmentIndex !== -1) {
      state.appointments[appointmentIndex].notes.push(newNote);
    }
  },
  UPDATE_APPOINTMENT_NOTE (state, updatedNote) {
    const appointmentIndex = state.appointments.findIndex(appointment => appointment.id === updatedNote.appointment.id);
    if (appointmentIndex !== -1) {
      const noteIndex = state.appointments[appointmentIndex].notes.findIndex(note => note.id === updatedNote.id);
      if (noteIndex !== -1) {
        state.appointments[appointmentIndex].notes.splice(noteIndex, 1, updatedNote);
      }
    }
  },
  DELETE_APPOINTMENT_NOTE (state, noteId) {
    const appointmentIndex = state.appointments.findIndex(appointment => appointment.notes.some(note => note.id === noteId));
    if (appointmentIndex !== -1) {
      state.appointments[appointmentIndex].notes = state.appointments[appointmentIndex].notes.filter(note => note.id !== noteId);
    }
  }
};

export const actions = {
  async fetchAppointments ({ commit }, params) {
    commit('SET_LOADING', true);
    const response = await apiv2.getAppointments(params);
    commit('SET_APPOINTMENTS', response.data);
    commit('SET_TOTAL_RECORDS', parseInt(response.headers['x-total-count']));
    commit('SET_LOADING', false);
    return response.data;
  },
  async createAppointmentToCareEvent ({ commit }, { careEventId, appointmentData }) {
    const newAppointment = await apiv2.addAppointmentToCareEvent(careEventId, appointmentData);
    commit('ADD_APPOINTMENT', newAppointment);
    return newAppointment;
  },
  async createAppointment ({ commit }, { profileId, appointmentData }) {
    const newAppointment = await apiv2.addAppointment(profileId, appointmentData);
    commit('ADD_APPOINTMENT', newAppointment);
    return newAppointment;
  },
  async updateAppointment ({ commit }, { appointmentId, appointmentData }) {
    const appointmentPayload = { ...appointmentData, id: appointmentId };
    const updatedAppointment = await apiv2.updateAppointment(appointmentId, appointmentPayload);
    commit('UPDATE_APPOINTMENT', updatedAppointment);
    return updatedAppointment;
  },
  async addNoteToAppointment ({ commit }, { appointmentId, notePayload }) {
    const newNote = await apiv2.addAppointmentNote(appointmentId, notePayload);
    commit('ADD_APPOINTMENT_NOTE', newNote);
    return newNote;
  },
  async updateNoteOnAppointment ({ commit }, { noteId, notePayload }) {
    const updatedNote = await apiv2.updateAppointmentNote(noteId, notePayload);
    commit('UPDATE_APPOINTMENT_NOTE', updatedNote);
    return updatedNote;
  },
  async deleteNoteOnAppointment ({ commit }, { noteId }) {
    await apiv2.deleteAppointmentNote(noteId);
    commit('DELETE_APPOINTMENT_NOTE', noteId);
  }
};

export const getters = {
  appointmentById: (state) => (id) => state.appointments.find(appointment => appointment.id === id),
  lastUpdatedDateFromNow: (state) => state.lastUpdatedDate ? state.lastUpdatedDate.fromNow() : null,
  getNextAppointmentByPrincipalId: (state) => (principalId) =>
    state.appointments
      .filter(app => app.principal.id === principalId)
      .sort((a, b) => moment(a.start).diff(moment(b.start)))
      .find(app => moment(app.start).isAfter(moment()))
};
