<template>
  <div :class="calcClass">
    <div class="content">
      <slot/>
    </div>
    <div class="progress" v-if="loading">
      <h3 v-if="text">{{text}}</h3>
      <v-progress-circular size='20' indeterminate color="primary" />
    </div>
  </div>
</template>
<script>
  import './ProgressContainer.scss';

  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      classes: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      }
    },
    computed: {
      calcClass() {
        return 'progress-container' + (this.loading ? ' loading' : '') + (this.classes ? ' ' + this.classes : '');
      }
    }
  }
</script>
