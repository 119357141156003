import packageJson from '../package.json'
import { Config } from '@/config.interface'
import env from '../env.json'
const version = packageJson.version
const envName = window.location.hostname

const config: Config = {
    RELEASE_TAG: import.meta.env.VITE_RELEASE_TAG || `dev-${version}`,
    REGION_INFO_URL: env[envName].REGION_INFO_URL,
    APIV2_URL: env[envName].APIV2_URL,
    AMCHARTS_KEY: env[envName].AMCHARTS_KEY,
    AGGRID_KEY: env[envName].AGGRID_KEY,
    DATADOG_SITE_URL: env[envName].DATADOG_SITE_URL || 'datadoghq.eu',
    DATADOG_CLIENT_TOKEN: env[envName].DATADOG_CLIENT_TOKEN,
    DATADOG_ENABLED: env[envName].DATADOG_ENABLED === 'true',
    ENV_NAME: env[envName].ENV_NAME,
    PURE_PRIMEVUE: true
}

export default config
