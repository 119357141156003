import { App } from 'vue';
import config from "@/config";

// Conditional import of styles
if (!config.PURE_PRIMEVUE) {
    // TODO: TO be removed after PrimeVue is added
    import('./vuetify-style')
}
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

export default {
    install: (app: App) => {
        const vuetify = createVuetify({
            theme: {
                themes: {
                    light: {
                        colors: {
                            primary: "#0073E6",
                            secondary: "#E0F0FF",
                            accent: "#82B1FF",
                            error: "#FF5252",
                            info: "#2196F3",
                            success: "#4CAF50",
                            warning: "#FFC107"
                        }
                    }
                }
            },
            components,
            directives
        });
        app.use(vuetify);
    }
};
