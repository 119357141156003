<template>
    <div class="body-lg-width mt-5">
        <div class="grid">
            <div v-for="site in sitesSorted" v-bind:key="site.id" class="col-4 p-3">
                <router-link class='site-link' :to="`/site/${slugFromSite(site)}`">
                    <Card class="zoom-card">
                        <template #title>
                            <span>{{ site.long_name }}</span>
                        </template>
                        <template #subtitle>Patients: {{ site.profile_count }}</template>
                        <template #content>
                            <div class="flex justify-content-end">
                                <Chip class='mx-1' small v-if="asthmaText(site)" :label="asthmaText(site)" />
                                <Chip class='mx-1' small v-if="cfText(site)" :label="cfText(site)" />
                                <Chip class='mx-1' small v-if="!cfText(site) && !asthmaText(site)" label="Default" />
                            </div>
                        </template>
                    </Card>
                </router-link>
            </div>
        </div>
    </div>
</template>
  
<script>
import { slugFromSite } from "@/utils/modelUtil";
import PortalPageMixin from "@/mixins/PortalPageMixin";

export default {
    name: "SitesList",
    middleware: ["auth"],
    mixins: [PortalPageMixin],
    computed: {
        sitesSorted () {
            const s = [...this.sites];
            s.sort((a, b) => a.long_name.toLowerCase().localeCompare(b.long_name.toLowerCase()));
            return s;
        }
    },
    methods: {
        slugFromSite,
        asthmaText (site) {
            return site.conditions.find(condition => condition.name.toLowerCase() === 'asthma') ? "Asthma" : "";
        },
        cfText (site) {
            return site.conditions.find(condition => condition.name.toLowerCase() === 'cf') ? "Cystic Fibrosis" : "";
        }
    }
};
</script>
<style lang="scss" scoped>
.zoom-card {
    transition: transform .2s;
    margin: 0 auto;
}

.zoom-card:hover {
    transform: scale(1.05);
}

.site-link {
    text-decoration: none;
}
</style>
