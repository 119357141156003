import { ref, computed } from "vue";
import moment from "moment";
import apiv2 from "@/api";
export function useCareEvents () {
  // State
  const loading = ref(false);
  const events = ref([]);
  const fetchParams = ref({});
  const eventTypes = ref([]);
  const lastUpdatedDate = ref(null);
  const totalRecords = ref(0);

  // Getters
  const lastUpdatedDateFromNow = computed(() => lastUpdatedDate.value ? moment(lastUpdatedDate.value).fromNow() : null);
  const isLoading = computed(() => loading.value);
  const isEventTypesLoaded = computed(() => eventTypes.value.length > 0);
  const eventTypeOptions = computed(() => eventTypes.value.map(type => ({ label: type.name, id: type.id })));

  // Actions

  async function fetchAdverseEventTypes () {
    if (isEventTypesLoaded.value) return;
    eventTypes.value = await apiv2.getAdverseEventTypes();
  }

  function setFetchParams(params) {
    fetchParams.value = params
  }

  async function fetchCareEvents (params) {
    loading.value = true;
    try {
      const queryParams = params ?? fetchParams.value;
      const response = await apiv2.getCareEvents(queryParams);
      console.log(response.data);
      events.value = response.data;
      totalRecords.value = parseInt(response.headers['x-total-count']);
      lastUpdatedDate.value = moment();
      return response.data;
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  async function createCareEvent ({ profileId, eventData }) {
    const newEvent = await apiv2.addCareEvent(profileId, eventData);
    events.value.push(newEvent);
    return newEvent;
  }

  async function updateCareEvent ({ eventId, eventData }) {
    const eventPayload = { ...eventData, id: eventId };
    const updatedEvent = await apiv2.updateCareEvent(eventId, eventPayload);
    const eventIndex = events.value.findIndex(event => event.id === updatedEvent.id);
    if (eventIndex !== -1) {
      const updatedEventActuality = updatedEvent.actuality;
      if(fetchParams.value.actuality === updatedEventActuality){
        events.value.splice(eventIndex, 1, updatedEvent);
      }
      else{
        events.value.splice(eventIndex, 1);
      }
    }
    return updatedEvent;
  }

  async function addNoteToEvent ({ logId, notePayload }) {
    const newNote = await apiv2.addCareEventNote(logId, notePayload);
    const eventIndex = events.value.findIndex(event => event.id === newNote.adverseEvent.id);
    if (eventIndex !== -1) {
      events.value[eventIndex].notes.push(newNote);
    }
    return newNote;
  }

  async function updateNoteOnEvent ({ noteId, notePayload }) {
    const updatedNote = await apiv2.updateCareEventNote(noteId, notePayload);
    const eventIndex = events.value.findIndex(event => event.id === updatedNote.adverseEvent.id);
    if (eventIndex !== -1) {
      const noteIndex = events.value[eventIndex].notes.findIndex(note => note.id === updatedNote.id);
      if (noteIndex !== -1) {
        events.value[eventIndex].notes.splice(noteIndex, 1, updatedNote);
      }
    }
    return updatedNote;
  }

  async function deleteNoteOnEvent ({ noteId }) {
    await apiv2.deleteCareEventNote(noteId);
    const eventIndex = events.value.findIndex(event => event.notes.some(note => note.id === noteId));
    if (eventIndex !== -1) {
      events.value[eventIndex].notes = events.value[eventIndex].notes.filter(note => note.id !== noteId);
    }
  }

  return {
    // State
    loading,
    events,
    fetchParams,
    eventTypes,
    lastUpdatedDate,
    totalRecords,

    // Getters
    lastUpdatedDateFromNow,
    isLoading,
    isEventTypesLoaded,
    eventTypeOptions,

    // Actions
    fetchAdverseEventTypes,
    setFetchParams,
    fetchCareEvents,
    createCareEvent,
    updateCareEvent,
    addNoteToEvent,
    updateNoteOnEvent,
    deleteNoteOnEvent
  };
}
