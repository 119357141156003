<template>
  <Dialog :header="headerText" v-model:visible="showModal" :modal="true" :width="700" dismissableMask @hide="$emit('hide')"
    @show="$emit('show')">
    <div class="event-activity-form">
      <div class="flex flex-column mb-4">
        <div class="flex flex-column w-full">
          <label>Start Date</label>
          <Calendar v-model="event.startTime" :max-date="new Date()" :manualInput="false" :dateFormat="$dateFormat" />
        </div>
      </div>
      <div class="flex flex-column mb-4">
        <div class="flex flex-column w-full">
          <label>End Date</label>
          <Calendar v-model="event.endTime" class="rightPos" :dateFormat="$dateFormat" />
        </div>
      </div>
      <div class="flex flex-column mb-4">
        <label for="event-type">Type</label>
        <Dropdown v-model="event.type" :options="eventTypeOptions" optionLabel="label" optionValue="id"
          scrollHeight="300px"></Dropdown>
      </div>

      <div class="flex flex-column mb-4">
        <label for="event-source">Source</label>
        <Dropdown v-model="event.source" :options="sourcesOptions" optionLabel="label" optionValue="key"></Dropdown>
      </div>
      <div class="grid">
        <div class="flex align-items-center col-7">
          <Checkbox v-model="event.treatmentPlan" binary />
          <label class="ml-2">Treatment Plan Established</label>
        </div>
        <div class="flex align-items-center col-5">
          <Checkbox v-model="event.newPrescription" binary />
          <label class="ml-2">Need for new Rx</label>
        </div>
        <div class="flex align-items-center col-7">
          <Checkbox v-model="event.hospitalization" binary />
          <label class="ml-2">Hospitalization</label>
        </div>
        <div class="flex align-items-center  col-5">
          <Checkbox v-model="event.erUtilization" binary />
          <label class="ml-2">ER utilization</label>
        </div>
        <div class="flex align-items-center  col-7">
          <Checkbox v-model="event.outPatientVisit" binary />
          <label class="ml-2">Outpatient Visit</label>
        </div>
        <div class="flex align-items-center col-5">
          <Checkbox v-model="event.nuvoAirVisit" binary />
          <label class="ml-2">NuvoAir Visit</label>
        </div>
      </div>

      <LogNoteForm v-if="!isEditMode" @textChange="onNoteChange" disableControls />
    </div>
    <template #footer>
      <Button label="Cancel" @click="onCancel" />
      <Button :label="submitButtonText" :disabled="!canSubmit" @click="onSubmit" />
    </template>
  </Dialog>
</template>
  
<script>
import { sourceList } from './constants';
import LogNoteForm from '../common/LogNoteForm.vue'
import moment from 'moment'

const initEvent = {
  startTime: moment().toDate(),
  endTime: moment().add(4, 'weeks').toDate(),
  type: null,
  source: null,
  treatmentPlan: false,
  newPrescription: false,
  hospitalization: false,
  erUtilization: false,
  outPatientVisit: false,
  nuvoAirVisit: false,
  notes: null,
}

export default {
  components: {
    LogNoteForm,
  },
  data () {
    return {
      showModal: false,
      event: { ...initEvent },
      showNotes: false,
    }
  },
  props: {
    showEventActivityModal: {
      type: Boolean,
      default: false,
    },
    eventToEdit: {
      type: Object,
      default: false,
    },
    eventTypeOptions: {
      type: Array,
      default: () => [],
    },
  },
  mounted () {
    this.showModal = this.showEventActivityModal
  },
  computed: {
    isEditMode () {
      return !!this.eventToEdit
    },
    headerText () {
      return this.isEditMode ? 'Edit Event Activity' : 'Add Event Activity'
    },
    sourcesOptions () {
      return sourceList.filter(s => !s.hide)
    },
    submitButtonText () {
      return this.isEditMode ? 'Update' : 'Save'
    },
    canSubmit () {
      const canSubmitForm = this.event.startTime && this.event.type && this.event.source
      return !!canSubmitForm
    }
  },
  methods: {
    resetForm () {
      this.event = { ...initEvent }
      this.showNotes = false
    },
    onNoteChange (note) {
      this.event.notes = note
    },
    onSubmit () {
      const eventToSubmit = {
        startTime: this.event.startTime,
        endTime: this.event.endTime,
        type: {
          id: this.event.type
        },
        source: this.event.source,
        treatmentPlan: this.event.treatmentPlan,
        newPrescription: this.event.newPrescription,
        hospitalization: this.event.hospitalization,
        erUtilization: this.event.erUtilization,
        outPatientVisit: this.event.outPatientVisit,
        nuvoAirVisit: this.event.nuvoAirVisit,
      }
      if (this.isEditMode) {
        this.$emit('update', { eventId: this.event.id, eventData: eventToSubmit })
      }
      else {
        // * set a default value to ACTUAL
        eventToSubmit.actuality = 'ACTUAL'
        if (this.event.notes) {
          eventToSubmit.notes = [{ text: this.event.notes }]
        }
        this.$emit('save', eventToSubmit)
      }
    },
    onCancel () {
      this.resetForm()
      this.$emit('hide')
    },
  },
  watch: {
    showEventActivityModal (val) {
      this.showModal = val
      if (!val) {
        this.resetForm()
      }
    },
    eventToEdit (val) {
      if (val) {
        this.event = { ...val, type: val.type.id, startTime: new Date(val.startTime), endTime: val.endTime ? new Date(val.endTime) : null }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.event-activity-form {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 500px;
  margin: 0 auto;
  width: 33vw;
}


// ::v-deep .rigthPos>.p-datepicker {
//   right: 0;
//   left: auto;

// }

// .p-datepicker {
//   right: 0;
//   left: auto;
// }
</style>