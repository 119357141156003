import { getUpperLevelDomainFromLocation } from "@/lib/urls";

class GatewayUrlBuilder {
  #_domain = undefined;

  #getDomain = () => {
    if (this.#_domain === undefined) {
      this.#_domain = getUpperLevelDomainFromLocation();
      this.#_domain = (this.#_domain === "localhost")
          ? "http://localhost:9001"
          : `https://gateway.${this.#_domain}`;
    }
    return this.#_domain;
  };

  #build = (path, queryParams = {}, fragmentParams = {}) => {
    const url = new URL(path, this.#getDomain());

    // Append query parameters
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    // Append fragment parameters
    const fragment = Object.entries(fragmentParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    if (fragment) {
      url.hash = `#${fragment}`;
    }

    return url.href;
  };

  profileDetails = (profileId) => this.#build(`/profile/${profileId}`);
  addProfile = (siteId) => this.#build("/nuvoair-user/new-patient", {},{ siteId });
  aosSession = (sessionId) => this.#build(`/aos-session/${sessionId}`);
}

export default new GatewayUrlBuilder();