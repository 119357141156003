<template>
    <div>
        <div v-if="!showNoteTextArea">
            <Button class="p-button-text" label="Add note" icon="pi pi-align-left" @click="showNoteTextArea = true" />
        </div>
        <div :class="selfClass" v-else>
            <div class="w-full">
                <label v-if="!hideLabel" for="log-notes">Notes</label>
                <!-- <Textarea v-model="tempNotes" cols="100"></Textarea> -->
                <Editor v-model="tempNotes" @text-change="$emit('textChange', $event.htmlValue)" editorStyle="height: 150px;">
                    <template #toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-strike"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered" type="button"></button>
                            <button class="ql-list" value="bullet" type="button"></button>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-align">
                                <option selected=""></option>
                                <option value="center"></option>
                                <option value="right"></option>
                                <option value="justify"></option>
                            </select>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="flex align-items-center">
                <Button v-if="!disableControls" class="p-button-link ml-2" label="Cancel"
                    @click="resetTempNotes" />
                <Button v-if="!disableControls" class="ml-2" label="Save" :disabled="canSubmit"
                    @click="submitNote" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        disableControls: {
            type: Boolean,
            default: false
        },
        hideLabel: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
        vertical: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            tempNotes: '',
            showNoteTextArea: false
        }
    },
    computed: {
        selfClass() {
            return `flex align-items-end ${this.vertical ? 'flex-column' : ''}`;
        },
        canSubmit () {
            return !this.tempNotes || this.tempNotes.trim().length == 0
        }
    },
    methods: {
        resetTempNotes () {
            this.showNoteTextArea = false
            this.tempNotes = ''
            this.$emit('cancel')
        },
        submitNote () {
            this.$emit('save', this.tempNotes)
            this.resetTempNotes()
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (val) {
                this.tempNotes = val
                if (val) {
                    this.showNoteTextArea = true
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
</style>