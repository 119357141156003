import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import router from '@/router'
import { store } from '@/store';
import config from '@/config'

interface ErrorResponseData {
    message?: string;
    errors?: any[];
}

// Create an Axios instance
const instance: AxiosInstance = axios.create({
    baseURL: config.APIV2_URL,
    withCredentials: true
});

// Set up request interceptor to attach access_token to the headers
instance.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Set up response interceptor to check for status codes and handle 401 errors
instance.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, (error: AxiosError) => {
    const { status } = error.response!;

    if (status === 401 || status === 403) {
        router.push('/account/login');
        return Promise.reject(error);
    }

    try {
        const data = error.response!.data as ErrorResponseData;
        const errorMessage = data.message;
        if (errorMessage) {
            store.commit("snackbar/error", errorMessage);
        } else {
            data.errors?.forEach((err: any) => {
                const errorString = typeof err === 'string' ? err : err.message || err.error;

                if (errorString) {
                    store.commit("snackbar/error", errorString);
                }
            });
        }
    } catch (e) {
        console.log(`AXIOS ${error.config.method?.toUpperCase()} '${error.config.url}' error`, error);
        store.commit('snackbar/error', `Server Error for ${error.config.url}:` + error.toString());
    }

    return Promise.reject(error);
});

export default instance;