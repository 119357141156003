<template>
    <div>
        <div class="notes-wrapper" :class="{ 'empty': isLogNotesEmpty }">
            <div v-for="note in sortedLogNotes" :key="note.id"
                class="flex flex-column justify-content-between p-2 note-container">
                <div class="flex">
                    <div class="w-full">
                        <div class="flex note__timestamps" v-if="!inEditMode(note)">
                            <span v-if="note.createdDate">{{ formatDateTime(note.createdDate) }}</span>
                            <span v-if="isNoteModified(note)" class="ml-2">Modified: {{
                                formatDateTime(note.lastModifiedDate) }}</span>
                        </div>
                        <LogNoteForm v-if="inEditMode(note)" hideLabel :value="tempNoteToEdit.text" @cancel="cancelEditNote"
                            @save="(noteText) => onNoteUpdate(note.id, noteText)" :vertical="verticalNoteForm" />
                        <div v-else v-html="note.text"></div>
                    </div>

                    <div class="flex justify-content-end align-items-center" v-if="!inEditMode(note) && canEditNote(note)">
                        <Button icon="pi pi-pencil" class="p-button-text ml-2" :disabled="!canEditNote(note)"
                            @click="editNote(note)" />
                        <Button icon="pi pi-trash" class="p-button-text ml-2" :disabled="!canEditNote(note)"
                            @click="deleteNote(note)" />
                    </div>
                </div>

            </div>
        </div>
        <LogNoteForm hideLabel @save="(noteText) => onNoteSave(noteText)" :vertical="verticalNoteForm"/>
    </div>
</template>
<script>
import moment from 'moment'
import { formatDateTime } from "@/utils/dateUtil";
import LogNoteForm from './LogNoteForm.vue'
const EDIT_TIMEOUT_MS = 24 * 60 * 60 * 1000 // 24 hours 

export default {
    components: {
        LogNoteForm
    },
    props: {
        logId: {
            type: String,
            default: null
        },
        notes: {
            type: Array,
            default: () => []
        },
        verticalNoteForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            formatDateTime: (date) => formatDateTime(date, 'LLL'),
            tempNoteToEdit: null
        }
    },
    computed: {
        sortedLogNotes () {
            return this.notes.slice().sort((a, b) => moment(a.createdDate) - moment(b.createdDate))
        },
        isLogNotesEmpty () {
            return this.notes.length === 0
        },
    },
    methods: {
        isNoteModified (logNote) {
            return logNote.lastModifiedDate && logNote.lastModifiedDate !== logNote.createdDate
        },
        canEditNote (logNote) {
            return logNote.createdBy === this.$store.state.auth.user.id && moment(logNote.createdDate) > moment().subtract(EDIT_TIMEOUT_MS, 'milliseconds')
        },
        editNote (note) {
            this.tempNoteToEdit = note
        },
        cancelEditNote () {
            this.tempNoteToEdit = null
        },
        inEditMode (note) {
            return this.tempNoteToEdit && this.tempNoteToEdit.id == note.id
        },
        onNoteUpdate (noteId, noteText) {
            const newNote = {
                id: noteId,
                text: noteText,
            }
            this.$emit('update-note', { noteId: noteId, notePayload: newNote })
            this.tempNoteToEdit = null
        },
        deleteNote (note) {
            this.$emit('delete-note', { noteId: note.id })
        },
        onNoteSave (tempNotes) {
            const newNote = {
                text: tempNotes,
            }
            this.$emit('add-note', { logId: this.logId, notePayload: newNote })
        }
    }
}
</script>
<style lang="scss" scoped>
.notes-wrapper {
    margin: 16px 0 8px;
    background-color: #f7f7f7;
    border-radius: 4px;

    &:not(.empty) {
        border: 1px solid #ccc;
    }
}

.note-container:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.note {
    // TODO: to be removed after Vuetify is removed
    :deep ul {
        padding-inline-start: 40px;
    }

    &__timestamps {
        font-size: 11px;
        color: #808080;
    }
}
</style>