import { App } from 'vue';
import { datadogLogs } from '@datadog/browser-logs';

export default {
  install: (app: App, options: any) => {
    if (options.DATADOG_ENABLED === true) {
      datadogLogs.init({
        site: options.DATADOG_SITE_URL,
        service: 'portal-frontend',
        env: options.ENV_NAME,
        version: options.RELEASE_TAG,
        clientToken: options.DATADOG_CLIENT_TOKEN,
        // isCollectingError: true,
        forwardErrorsToLogs: true,
        sampleRate: 100
      });
    }
  }
};
