<template>
  <div class="log-activity-form">
    <div class="form-group" v-if="!hiddenFields.logDate">
      <label for="log-date">Date</label>
      <Calendar v-model="log.logDate" :minDate="minDate" :manualInput="false" :maxDate="maxDate" showTime
        hourFormat="12" :disabled="disabledFields.logDate" appendTo="body" />
    </div>

    <div class="form-group" v-if="!hiddenFields.medium">
      <label for="log-channel">Channel</label>
      <div class="radio-group">
        <div v-for="channel of channelOptions" :key="channel.key" class="field-radiobutton">
          <RadioButton :id="channel.key" name="channel" :value="channel.key" v-model="log.medium"
            :disabled="disabledFields.medium" />
          <label :for="channel.key">{{ channel.label }}</label>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="pastActivity && !hiddenFields.direction">
      <label for="log-channel">Direction</label>
      <div class="radio-group">
        <div v-for="dir of directionOptions" :key="dir.key" class="field-radiobutton">
          <RadioButton :id="dir.key" name="dir" :value="dir.key" v-model="log.direction" />
          <label :for="dir.key" class="align-self-center">{{ dir.label }}</label>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="!hiddenFields.purpose">
      <label for="log-purpose">Purpose</label>
      <Dropdown v-model="log.purpose" :options="purposesOptions" optionLabel="label" optionValue="key"
        scrollHeight="300px" appendTo="body" :disabled="disabledFields.purpose"></Dropdown>
    </div>

    <div class="form-group" v-if="!pastActivity && siteId && !hiddenFields.assignedPractioner">
      <label for="log-purpose">Assign to</label>
      <Dropdown v-model="log.assignedPractitioner" :options="assignedPractitionerOptions" optionLabel="fullName"
        optionValue="id" scrollHeight="300px" appendTo="body" :disabled="disabledFields.assignedPractitioner">
      </Dropdown>
    </div>

    <div class="form-group" v-if="pastActivity && !hiddenFields.communicationResult">
      <label for="log-result">Result</label>
      <Dropdown v-model="log.communicationResult" :options="resultsOptions" optionLabel="label" optionValue="key"
        appendTo="body"></Dropdown>
    </div>

    <div v-if="pastActivity && !isSMS && !hiddenFields.subject" class="form-group">
      <label for="log-subject">Subject</label>
      <InputText v-model="log.subject"></InputText>
    </div>

    <div v-if="pastActivity && !isSMS && !hiddenFields.duration" class="form-group">
      <label for="log-duration">Duration (minutes)</label>
      <InputNumber v-model="log.duration" :min="1" :maxFractionDigits="2"></InputNumber>
    </div>

    <div class="form-group" v-if="pastActivity && isSMS && !hiddenFields.payload">
      <label for="log-payload">Message</label>
      <Textarea v-model="log.payload"></Textarea>
    </div>

    <LogNoteForm v-if="!prefill" @textChange="onNoteChange" disableControls />
  </div>
</template>

<script>
import moment from 'moment';
import LogNoteForm from '../common/LogNoteForm.vue';
import { channelList, purposeList, directionList, resultList } from './constants';
import { createNamespacedHelpers } from 'vuex';
const portalVuex = createNamespacedHelpers('portal');

export default {
  components: {
    LogNoteForm,
  },
  props: {
    logToEdit: {
      type: Object,
      default: null,
    },
    siteId: {
      type: String,
      required: false
    },
    pastActivity: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Object,
      default: () => ({})
    },
    hiddenFields: {
      type: Object,
      default: () => ({})
    },
    prefill: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...portalVuex.mapGetters(['siteUsersBySiteId']),
    log: {
      get () { return this.logToEdit },
      set (value) { this.$emit('update:logToEdit', value) },
    },
    minDate () {
      return this.pastActivity ? null : moment().subtract(1, 'day').toDate()
    },
    maxDate () {
      return this.pastActivity ? moment().add(1, 'day').toDate() : null
    },
    mediumKey () {
      return this.log.medium
    },
    isSMS () {
      return this.mediumKey === 'WRITTEN_SMS'
    },
    channelOptions () {
      return channelList.filter(channel => !channel.hidden)
    },
    purposesOptions () {
      return purposeList
    },
    resultsOptions () {
      return resultList[this.mediumKey]
    },
    directionOptions () {
      return directionList;
    },
    assignedPractitionerOptions () {
      const siteUsers = this.siteUsersBySiteId(this.siteId)
      return siteUsers.filter(user => user.firstName || user.lastName).map(user => ({ ...user, fullName: [user?.firstName, user?.lastName].filter(Boolean).join(' ') }))
    }
  },
  methods: {
    onNoteChange (note) {
      this.log = {
        ...this.log,
        notes: note,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.log-activity-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.radio-group {
  display: flex;
  margin-top: 8px;

  div {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 8px 0 4px;
    font-weight: bold;
    align-self: flex-start;
  }
}
</style>