import { createStore } from 'vuex'

// main store
// import state from './state'
// import getters from './getters'
// import mutations from './mutations'
// import actions from './actions'

// Old Modules
import * as portal from './portal'
import * as profile from './profile'
import * as profiles from './profiles'
import * as timeline from './timeline'
import * as devices from './devices'
import * as layout from './layout'
import * as overwriting from './overwriting'
import * as snackbar from './snackbar'
import * as sidebar from './sidebar'
import * as zoom from './zoom'
import * as asthmaReportView from './asthmaReportView'
import * as asthmaReport from './asthmaReport'
import * as communicationPreferences from './communicationPreferences'
import * as unitsAndFormats from './unitsAndFormats'
import * as communicationLogs from './communicationLogs'
import * as filters from './filters'
import * as questionnaires from './questionnaires'
import * as appointments from './appointments'

// New Modules
import auth from './auth'

export const store = createStore({
  //   state,
  //   getters,
  //   mutations,
  //   actions,
  modules: {
    auth,
    portal: {
      state: portal.state, 
      mutations: portal.mutations,
      getters: portal.getters,
      namespaced: true
    },
    snackbar: {
      state: snackbar.state, 
      mutations: snackbar.mutations,
      namespaced: true
    },
    profile: {
      state: profile.state, 
      mutations: profile.mutations,
      actions: profile.actions,
      getters: profile.getters,
      namespaced: true
    },
    profiles: {
      state: profiles.state, 
      mutations: profiles.mutations,
      namespaced: true
    },
    unitsAndFormats: {
      state: unitsAndFormats.state, 
      mutations: unitsAndFormats.mutations,
      namespaced: true
    },
    timeline: {
      state: timeline.state, 
      mutations: timeline.mutations,
      namespaced: true
    },
    devices: {
      state: devices.state, 
      mutations: devices.mutations,
      actions: devices.actions,
      getters: devices.getters,
      namespaced: true
    },
    appointments: {
      state: appointments.state, 
      mutations: appointments.mutations,
      actions: appointments.actions,
      getters: appointments.getters,
      namespaced: true
    },
    layout: {
      state: layout.state, 
      mutations: layout.mutations,
      namespaced: true
    },
    communicationPreferences: {
      state: communicationPreferences.state, 
      mutations: communicationPreferences.mutations,
      namespaced: true
    },
    asthmaReport: {
      state: asthmaReport.state, 
      mutations: asthmaReport.mutations,
      actions: asthmaReport.actions,
      namespaced: true
    },
    asthmaReportView: {
      state: asthmaReportView.state, 
      mutations: asthmaReportView.mutations,
      namespaced: true
    },
    overwriting: {
      state: overwriting.state, 
      mutations: overwriting.mutations,
      namespaced: true
    },
    communicationLogs: {
      state: communicationLogs.state, 
      mutations: communicationLogs.mutations,
      actions: communicationLogs.actions,
      getters: communicationLogs.getters,
      namespaced: true
    },
    filters: {
      state: filters.state, 
      mutations: filters.mutations,
      actions: filters.actions,
      getters: filters.getters,
      namespaced: true
    },
    questionnaires: {
      state: questionnaires.state, 
      mutations: questionnaires.mutations,
      actions: questionnaires.actions,
      getters: questionnaires.getters,
      namespaced: true
    },
    sidebar: {
      state: sidebar.state, 
      mutations: sidebar.mutations,
      actions: sidebar.actions,
      namespaced: true
    },
    zoom: {
      state: zoom.state, 
      mutations: zoom.mutations,
      actions: zoom.actions,
      getters: zoom.getters,
      namespaced: true
    }
  }
})