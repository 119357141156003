import { ACTION_TYPE_SITE_USER_EDIT } from "@/PortalConstants";
import { processSiteLoaded } from "@/utils/siteUtil";

//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  settings: undefined, // https://localhost:8000/portal_admin/settings
  units: "metric",
  site: undefined,
  sites: [],
  myUsers: [],
  sitesById: {},
  selectedSite: undefined,
  usersInvalidated: false,
  sitesInvalidated: false,
  sessionExpirationDate: undefined,
  sessionExpired: false,
  siteUser: {},
  portalUser: null,
  dialogs: {
    addUser: {
      show: false,
      user: {
        isAdminFor: {},
        portalConfiguration: {
          twoFactorAuthentication: false
        },
        phoneNumber: "",
        phoneCountryCode: "",
        firstName: "",
        lastName: "",
        siteIds: ""
      }
    },
    editUser: {
      show: false,
      user: {
        id: undefined,
        isAdminFor: {},
        portalConfiguration: {
          twoFactorAuthentication: false
        },
        phoneNumber: "",
        phoneCountryCode: "",
        firstName: "",
        lastName: "",
        siteIdsWithPermissions: [],
        siteIdsWithoutPermissions: []
      }
    }
  },
  ui: {
    subheader: {
      show: true,
      showBreadcrumbs: true,
      controlsShown: "site-settings",
      showControls: true
    }
  }
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setSessionExpirationDate: function (state, expirationDate) {
    state.sessionExpirationDate = expirationDate;
  },
  setSessionExpired (state, value) {
    state.sessionExpired = value;
  },
  setPortalSettings: function (state, settings) {
    state.settings = settings;
  },
  setUnitTypes: function (state, units) {
    state.units = units;
  },
  setSite: function (state, site) {
    state.site = site;
  },
  setSites: function (state, sites) {
    sites = sites.map(processSiteLoaded);

    state.sitesInvalidated = false;

    sites.forEach(site => (state.sitesById[site.id] = site));

    state.sites = sites;
  },
  setMyUsers: function (state, myUsers) {
    state.myUsers = myUsers;
    state.usersInvalidated = false;
  },
  selectSite: function (state, site) {
    state.selectedSite = site;
  },
  openDialog: function (state, dialogName) {
    state.dialogs[dialogName].show = true;
  },
  closeDialog: function (state, dialogName) {
    state.dialogs[dialogName].show = false;
  },
  closeAllDialogs: function (state) {
  },
  setEditUserDialogUser: function (state, { user, vueComponent }) {
    const isAdminFor = {};
    const isTwoFactorFor = {};

    user.site_users.forEach(su => (isAdminFor[su.site_id] = su.roles.find(role => role.code === "portal_site_admin")));

    state.dialogs[DIALOG_EDIT_USER].user = {
      id: user.id,
      accountConfirmed: user.account_confirmed,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      phoneNumber: user.phone_number,
      phoneCountryCode: user.phone_country_code,
      isAdminFor,
      portalConfiguration: {
        twoFactorAuthentication: user.portal_configuration ? user.portal_configuration.two_factor_authentication : false
      },
      siteIdsWithPermissions: user.site_users
        .map(su => su.site_id)
        .filter(site_id => vueComponent.hasPermissionInSite(site_id, ACTION_TYPE_SITE_USER_EDIT)),
      siteIdsWithoutPermissions: user.site_users
        .map(su => su.site_id)
        .filter(site_id => !vueComponent.hasPermissionInSite(site_id, ACTION_TYPE_SITE_USER_EDIT))
    };
  },
  invalidateSites: function (state) {
    state.sitesInvalidated = true;
  },
  invalidateUsers: function (state) {
    state.usersInvalidated = true;
  },
  resetAddUserDialog: function (state) {
    state.dialogs[DIALOG_ADD_USER].user = {
      isAdminFor: {},
      portalConfiguration: {
        twoFactorAuthentication: false
      },
      phoneNumber: "",
      phoneCountryCode: "",
      firstName: "",
      lastName: "",
      siteIds: ""
    };
  },
  resetSites: function (state) {
    state.sites = [];
    state.sitesById = {};
  },
  resetSettings: function (state) {
    state.settings = undefined;
  },
  setPortalUser: function (state, portalUser) {
    state.portalUser = portalUser;
  },
};
export const getters = {
  getCurrentSite (state) {
    return state.site;
  },
  userId: s => s.portalUser && s.portalUser.id,
  userFullName: s => s.portalUser && `${s.portalUser.first_name} ${s.portalUser.last_name}`,
  currentSiteId: (_, getters) => getters.getCurrentSite && getters.getCurrentSite.id,
  getSiteByShortName: state => shortName => state.sites.find(site => site.short_name === shortName),
  siteUsersBySiteId: state => siteId => state.sites.find(site => site.id === siteId).users,
  allSiteUsers: state => state.sites.map(site => site.users).flat()
    .filter((user, index, self) => self.findIndex(u => u.id === user.id) === index),
  siteUsers: state => state.portalUser && state.portalUser.site_users.map(item => item.site_user),
  getSiteUserById: (_, getters) => id => getters.siteUsers.find(siteUser => siteUser.site_id === id),
  currentSiteUser: (_, getters) => getters.getSiteUserById(getters.currentSiteId),
};
