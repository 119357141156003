import { SIDEBAR_TAGS, PERSISTENT_SIDEBARS } from "@/components/layout/sidebar/SidebarConstants";

export const state = () => ({
  activeSidebars: [],
  expanded: false,
  maximizedId: null,
  nextId: 1,
});

export const mutations = {
  ADD_SIDEBAR (state, {tag, payload}) {
    state.activeSidebars.push({
      id: state.nextId,
      tag,
      payload,
    });
    state.nextId += 1;
  },
  REMOVE_SIDEBAR (state, id) {
    state.activeSidebars = state.activeSidebars.filter(s => s.id !== id);
  },
  UPDATE_PAYLOAD (state, {sidebarId, payload}) {
    const existingSidebar = state.activeSidebars.find(s => s.id === sidebarId);
    if (existingSidebar) {
      existingSidebar.payload = payload;
    }
  },
  SET_EXPANDED (state, expanded) {
    state.expanded = expanded;
  },
  SET_MAXIMIZED_ID (state, sidebarId) {
    state.maximizedId = sidebarId;
  },
};

export const actions = {
  expandSidebar ({ commit, state }) {
    if (!state.expanded) {
      commit('SET_EXPANDED', true);
    }
  },
  collapseSidebar ({ commit, state }) {
    if (state.expanded) {
      commit('SET_EXPANDED', false);
    }
  },
  addSidebar ({commit, state}, payload) {
    if (!payload || !payload.tag) {
      console.error('Tag required to add a sidebar.');
    } 

    if (!Object.values(SIDEBAR_TAGS).includes(payload.tag)) {
      console.warn('Provided sidebar tag is not recognized: ', payload.tag);
    }

    const sidebar = state.activeSidebars.find(s => s.tag === payload.tag);

    if (sidebar && PERSISTENT_SIDEBARS.includes(payload.tag)) {
      // Once a persistent sidebar is mounted, addSidebar will just update its payload.
      const updatedPayload = {
        ...sidebar.payload,
        hidden: false,
        ...payload,
      }
      commit('UPDATE_PAYLOAD', {sidebarId: sidebar.id, payload: updatedPayload});
    } else {
      commit('ADD_SIDEBAR', payload);
    }
  },
  removeSidebar({commit, state}, sidebarId) {
    if (!sidebarId) {
      console.error('ID required to remove a sidebar');
      return;
    }
    
    const sidebar = state.activeSidebars.find(s => s.id === sidebarId);

    if (!sidebar) {
      console.error('No sidebar found with id ', sidebarId);
      return;
    }
    
    if (PERSISTENT_SIDEBARS.includes(sidebar.tag)) {
      // Instead of unmounting a persistent sidebar, its payload is updated.
      const payload = {
        ...sidebar.payload,
        hidden: true,
      }
      commit('UPDATE_PAYLOAD', {sidebarId, payload});
    } else {
      commit('REMOVE_SIDEBAR', sidebarId);
    }
  },
  maximizeSidebar({state, commit}, sidebarId) {
    const hasSidebarId = state.activeSidebars.some(s => s.id === sidebarId);
    commit('SET_MAXIMIZED_ID', hasSidebarId ? sidebarId : null);
  },
  minimizeSidebar({commit}) {
    commit('SET_MAXIMIZED_ID', null);
  },
};
export const getters = {};