<template>
    <div class="dashboard-wrapper">
        <div class="flex flex-column mb-2">
            <div class="flex align-items-center breadcrumbs mb-2">
                <i class="pi pi-home" style="font-size: 1.5rem;"></i>
                <span class="ml-1 font-bold">HOME</span>
            </div>
            <h2 class="mb-3">
                Welcome, {{ userFullName }}!
            </h2>
        </div>
        <CareEventsTable selectedView="potential" consolidateSites />
        <CareEventsTable selectedView="actual" consolidateSites />
        <AppointmentsTable consolidateSites />
    </div>
</template>

<script>
import CareEventsTable from '@/components/careEvents/CareEventsTable.vue';
import AppointmentsTable from '@/components/communication/appointments/AppointmentsTable.vue';
import { createNamespacedHelpers } from 'vuex'
const portalVuex = createNamespacedHelpers('portal')
export default {
    components: {
        CareEventsTable,
        AppointmentsTable
    },
    mounted () {
        console.log(this.userFullName)
    },
    computed: {
        ...portalVuex.mapGetters(['userFullName'])
    }
}
</script>
<style lang="scss" scoped>
.dashboard-wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

h2 {
    font-size: 24;
    font-weight: 500;
}

.breadcrumbs {
    color: #0073E6;
}
</style>