
import { createRouter, createWebHistory } from "vue-router";
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { store } from "@/store";
import authManager from "@/auth/AuthManager";

const routes = setupLayouts(generatedRoutes)

console.log(routes.find(r => r.path === '/site/:slug/patient/:site_profile_id').component)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  console.log("router.beforeEach", to.path, from.path);

  // Check for SSO token first
  if (!authManager.isLoggedIn) {
    await authManager.checkSSOToken(); // Ensure this is async if it makes network calls
    if (authManager.isLoggedIn) {
      await store.dispatch("auth/bootstrap");
    }
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (authManager.isLoggedIn) {
    if (!store.getters['auth/user']) {
      await store.dispatch("auth/bootstrap");
    }
    if (to.path === '/account/login') {
      await store.dispatch("auth/logout");
    }
  } else if (requiresAuth && to.path !== '/account/login') {
    // Redirect non-authenticated users to the login page
    return { path: "/account/login" };
  }
});


router.onError((error) => {
  console.error('Routing error:', error);
  // Here you can handle the error or report it to an error tracking service
  // For example, you might want to redirect to a generic error page
  // router.push('/error-page');
});

export default router;