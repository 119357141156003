export const purposeList = [
    { label: 'Service Awareness', key: 'SERVICE_AWARENESS' },
    { label: 'Onboarding', key: 'ONBOARDING' },
    { label: 'Care Event', key: 'CARE_EVENT' },
    { label: 'Health Coaching', key: 'HEALTH_COACHING' },
    { label: 'Respiratory Therapy', key: 'RESPIRATORY_THERAPY' },
    { label: 'Remote Patient Monitoring', key: 'REMOTE_PATIENT_MONITORING' },
    { label: 'Digital Support', key: 'DIGITAL_SUPPORT' },
    { label: 'Self Report', key: 'SELF_REPORT' }, // support showing on create
    { label: 'Appointment', key: 'APPOINTMENT' },
    { label: 'Offboarding', key: 'OFF_BOARDING' },
    { label: 'Consumables', key: 'CONSUMABLES' },
    { label: 'Testimonial', key: 'TESTIMONIAL' },
]


export const channelList = [
    { label: 'Phone', key: 'VERBAL_PHONE' },
    { label: 'Video Conference', key: 'VERBAL_VIDEO_CONFERENCE' },
    { label: 'SMS', key: 'WRITTEN_SMS' },
    { label: 'SMS', key: 'AUTOMATED_SMS', hidden: true },
    // "VERBAL_PHONE",
    // "VERBAL_VIDEO_CONFERENCE",
    // "WRITTEN_SMS",
    // "VERBAL_AUTOMATED",
    // "VERBAL_IN_PERSON",
    // "WRITTEN_HAND",
    // "WRITTEN_MAIL",
    // "WRITTEN_EMAIL",
    // "WRITTEN_PUSH_NOTIFICATION",
    // "WRITTEN_MMS",
    // "WRITTEN_MESSAGING_APP"
]

export const directionList = [
    { label: 'Outbound', key: 'OUTBOUND' },
    { label: 'Inbound', key: 'INBOUND' }
]

export const resultList = {
    "VERBAL_PHONE": [
        { label: 'In Progress', key: 'IN_PROGRESS'},
        { label: 'Answered', key: 'ANSWERED' },
        { label: 'Message Machine', key: 'MESSAGE_MACHINE' },
        { label: 'Busy', key: 'BUSY' },
        { label: 'Rejected', key: 'REJECTED' },
        { label: 'No Answer', key: 'NO_ANSWER' }
    ],
    "VERBAL_VIDEO_CONFERENCE": [
        { label: 'Answered', key: 'ANSWERED' },
        { label: 'Message Machine', key: 'MESSAGE_MACHINE' },
        { label: 'Busy', key: 'BUSY' },
        { label: 'No Answer', key: 'NO_ANSWER' }
    ],
    "WRITTEN_SMS": [
        { label: 'Delivered', key: 'DELIVERED' },
        { label: 'Error', key: 'ERROR' },
        { label: 'Unknown', key: 'UNKNOWN' },
    ],
}

export const systemNoteSubjects = [
    'Patient Phone', 
    'SMS Note', 
    'Patient Notification Detail', 
    'Zoom Import Note', 
    'Zoom SMS Sent Import Note', 
    'Zoom SMS Received Import Note', 
    'Zoom Recording Note', 
    'Zoom Recording Transcript Note'
];