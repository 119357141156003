<template>
    <main>
        <RouterView />
    </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    // Your component options here
})
</script>