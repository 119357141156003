<template>
  <div>
    <DataTable :value="appointmentsRows" paginator lazy :totalRecords="totalRecords"
      @lazy-load="onLazyLoad" @page="onPageChange" @sort="onSortChange" @filter="onFilterChange"
      :rows="lazyParams.rows" sortField="startTime" :sortOrder="lazyParams.sortOrder" v-model:expandedRows="expandedRows"
      dataKey="id" responsiveLayout="scroll" collapsedRowIcon="pi pi-chevron-down" expandedRowIcon="pi pi-chevron-up"
      :loading="loading" v-model="filters" filterDisplay="menu">
      <template #empty>
        No appointments found.
      </template>
      <template #loading>
        Loading records, please wait...
      </template>
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column">
            <div class="flex align-items-center">
              <h3>{{ tableTitle }}</h3>
              <span class="ml-1 text-sm text-600">({{ displayDateRange }})</span>
            </div>
            <span class="text-sm text-600" :key="lastUpdateDateKey">
              last updated: {{ lastUpdatedDateFromNow() }}
            </span>
          </div>
          <div class="flex justify-content-end align-items-center">
            <AppointmentForm :hideActivator="consolidateSites" v-model:showLogActivityModal="showLogActivityModal"
              :appointmentToEdit="appointmentToEdit" @hide="onFormHidden" :siteId="tempSiteId" />
            <Button icon="pi pi-calendar" aria:haspopup="true" aria-controls="datePickerPanel"
              class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2" @click="toggleDatePickerPanel" />
            <OverlayPanel ref="datePickerPanel" appendTo="body" id="datePickerPanel">
              <Calendar v-model="dateFilter" selectionMode="range" showIcon :manualInput="false" :numberOfMonths="2"
                inputStyle="width: 180px;" :dateFormat="$dateFormat" />
            </OverlayPanel>
            <Button icon="pi pi-replay" class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
              @click="loadAppointments" />
          </div>
        </div>
      </template>
      <Column field="patientName" header="Patient" sortable v-if="consolidateSites">
        <template #body="slotProps">
          <router-link v-if="slotProps.data.patientUrl" :to="slotProps.data.patientUrl">{{ slotProps.data.patientName
          }}</router-link>
          <span v-else>{{ slotProps.data.patientName }}</span>
        </template>
      </Column>
      <Column field="startTime" header="Date" sortable>
        <template #body="slotProps">
          <div>{{ `${formatDate(slotProps.data.startTime)} | ${formatTime(slotProps.data.startTime)}` }}</div>
        </template>
      </Column>
      <Column field="medium" header="Channel" sortable>
        <template #body="slotProps">
          <span :class="channelIcon(slotProps.data.medium)"></span>
        </template>
      </Column>
      <Column field="purpose" header="Purpose" sortable :showFilterMatchModes="false">
        <template #body="slotProps">
          <span>{{ renderPurposeText(slotProps.data.purpose) || '-' }}</span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="purposeList" optionLabel="label" optionValue="key"
            placeholder="Select One" class="p-column-filter" showClear>
            <template #option="slotProps">
              <span>{{ slotProps.option.label || '-' }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="status" header="Status" sortable :showFilterMatchModes="false">
        <template #body="slotProps">
          <Badge :value="statusToText(slotProps.data.status)" :severity="statusSeverity(slotProps.data.status)" />
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Select One" class="p-column-filter"
            showClear>
            <template #option="slotProps">
              <Badge :value="statusToText(slotProps.option)" :severity="statusSeverity(slotProps.option)" />
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="tenant" header="Site" sortable v-if="consolidateSites" :showFilterMatchModes="false">
        <template #body="slotProps">
          <router-link v-if="slotProps.data.siteUrl && slotProps.data.tenant" :to="slotProps.data.siteUrl">{{
            slotProps.data.tenant }}</router-link>
          <span v-else-if="slotProps.data.tenant">{{ slotProps.data.tenant }}</span>
          <span v-else>-</span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="sites" placeholder="Any" optionValue="id"
            optionLabel="long_name" class="p-column-filter">
            <template #option="slotProps">
              <span>{{ slotProps.option.long_name }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="assignedPractitioner" header="Assigned to" sortable :showFilterMatchModes="false">
        <template #body="slotProps">
          <span>{{ getName(slotProps.data.assignedPractitionerPii) || '-' }}</span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="assignedPractitionerUsers" placeholder="Any" optionValue="id"
            optionLabel="name" class="p-column-filter">
            <template #option="slotProps">
              <span>{{ slotProps.option.name }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <span v-if="slotProps.data.notes && slotProps.data.notes.length > 0" class="pi pi-align-left"></span>
        </template>
      </Column>
      <Column :expander="true" :headerStyle="{ width: '5rem' }" />
      <template #expansion="slotProps">
        <AppointmentsTableItemDetails :appointment="slotProps.data" :inProfileView="!consolidateSites"
          @edit-appointment="openEditForm" @update-appointment-status="updateAppointmentStatus" />
      </template>
    </DataTable>
  </div>
</template>
  
<script>
import moment from 'moment';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { createNamespacedHelpers } from 'vuex';
import { formatDate, formatTime } from "@/utils/dateUtil";
import { capitalizeFirst, getNameFromPii, formatDateRange } from '@/helpers';
import { slugFromSite } from "@/utils/modelUtil";

import AppointmentForm from './AppointmentForm.vue';
import AppointmentsTableItemDetails from './AppointmentsTableItemDetails.vue';
import { purposeList } from '../constants.js';

const appointmentsVuex = createNamespacedHelpers('appointments');
const profileVuex = createNamespacedHelpers('profile');
const portalVuex = createNamespacedHelpers('portal');

export default {
  components: {
    AppointmentForm,
    AppointmentsTableItemDetails
  },
  props: {
    consolidateSites: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const filters = {
      purpose: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      status: {
        operator: FilterOperator.AND, constraints: [
          { value: 'PENDING', matchMode: FilterMatchMode.EQUALS },
        ]
      },
      assignedPractitioner: { value: null, matchMode: FilterMatchMode.EQUALS },
      tenant: { value: null, matchMode: FilterMatchMode.EQUALS },
    };
    return {
      dateFilter: [],
      interval: null,
      lastUpdateDateKey: 0,
      tableTitle: 'Appointments',
      formatDate,
      formatTime,
      appointmentToEdit: null,
      tempSiteId: null,
      expandedRows: [],
      showLogActivityModal: false,
      showNoteTextArea: false,
      tempNotes: '',
      filters: filters,
      purposeList,
      statuses: ['PENDING', 'BOOKED', 'FULFILLED', 'NO_SHOW', 'CANCELLED'],
      formatDateRange,
      lazyParams: {
        filters: filters,
        rows: this.consolidateSites ? 5 : 10,
        sortField: 'startTime',
        sortOrder: 1,
      },
    }
  },
  created () {
    this.setupAutoRefresh();
  },
  async mounted () {
    await this.loadAppointments();
    this.checkFocusItem(this.focusItem);
    const startDate = moment().subtract(1, 'month').startOf('day').toDate();
    const endDate = moment().add(1, 'day').endOf('day').toDate();
    this.dateFilter = [startDate, endDate];
  },
  destroyed () {
    this.tearDownAutoRefresh();
  },
  computed: {
    ...portalVuex.mapState(['sites']),
    ...portalVuex.mapGetters(['getSiteByShortName', 'siteUsersBySiteId', 'allSiteUsers', 'currentSiteId']),
    ...profileVuex.mapState(['focusItem']),
    ...profileVuex.mapGetters(['profileId']),
    ...appointmentsVuex.mapState(['appointments', 'loading', 'lastUpdatedDate', 'totalRecords']),
    ...appointmentsVuex.mapGetters(['appointmentById']),
    assignedPractitionerUsers () {
      const siteUsers = this.consolidateSites ? this.allSiteUsers : this.siteUsersBySiteId(this.currentSiteId)
      return siteUsers.filter(user => user.firstName || user.lastName).map(user => ({ ...user, name: this.getName(user) }))
        .sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()))
    },
    appointmentsRows () {
      if (this.consolidateSites) {
        return this.appointments.map(this.transformWithSiteInfo);
      }
      return this.appointments.map(this.transformWithoutSiteInfo);
    },
    displayDateRange () {
      return formatDateRange(this.dateFilter[0], this.dateFilter[1]);
    },
  },
  methods: {
    ...appointmentsVuex.mapActions(['fetchAppointments', 'updateAppointment']),

    // Initialization and Cleanup
    setupAutoRefresh () {
      if (this.consolidateSites) {
        this.interval = setInterval(() => {
          this.lastUpdateDateKey += 1
        }, 3000);
      }
    },

    tearDownAutoRefresh () {
      clearInterval(this.interval);
    },

    // Data Loading
    async loadAppointments () {
      try {
        if (!this.dateFilter || this.dateFilter.length !== 2 || !this.dateFilter.every(el => el !== null)) {
          return;
        }
        const startDate = moment(this.dateFilter[0]).startOf('day').toDate();
        const endDate = moment(this.dateFilter[1]).endOf('day').add(1, 'days').toDate();

        const params = {
          ...this.lazyParams,
          startDate: startDate,
          endDate: endDate
        };

        if (!this.consolidateSites) {
          params.profileId = this.profileId;
        }
        await this.fetchAppointments(params);
      } catch (e) {
        console.log(e);
      }
    },

    onLazyLoad (event) {
      this.lazyParams.page = event.page;
      this.loadAppointments();
    },

    onFilterChange (event) {
      this.lazyParams.filters = event.filters;
      this.loadAppointments();
    },
    onPageChange (event) {
      this.lazyParams.page = event.page;
      this.loadAppointments();
    },
    onSortChange (event) {
      this.lazyParams.sortField = event.sortField;
      this.lazyParams.sortOrder = event.sortOrder;
      this.loadAppointments();
    },

    // Appointment Utilities
    transformWithSiteInfo (appointment) {
      const site = this.getSiteByAppointment(appointment);
      const slug = site ? slugFromSite(site) : '';
      const profileId = this.getSiteProfileIdByAppointment(appointment);

      return {
        ...appointment,
        patientName: appointment.principalPii ? getNameFromPii(appointment.principalPii) : '',
        tenant: appointment.tenantsPii?.[0]?.longName,
        siteUrl: slug ? `/site/${slug}` : null,
        assignedPractitioner: this.getName(appointment.assignedPractitionerPii),
        patientUrl: slug && profileId ? `/site/${slug}/patient/${profileId}` : null
      };
    },

    transformWithoutSiteInfo (appointment) {
      return {
        ...appointment,
        assignedPractitioner: this.getName(appointment.assignedPractitionerPii)
      };
    },

    // UI Utilities
    channelIcon (medium) {
      switch (medium) {
        case 'VERBAL_PHONE':
          return 'pi pi-phone'
        case 'VERBAL_VIDEO_CONFERENCE':
          return 'pi pi-video'
        case 'WRITTEN_SMS':
        case 'AUTOMATED_SMS':
          return 'pi pi-comment'
        default:
          return ''
      }
    },
    renderPurposeText (purpose) {
      return purposeList.find(p => p.key === purpose)?.label
    },
    statusSeverity (status) {
      switch (status) {
        case 'PENDING':
        case 'PROPOSED':
          return 'warning'
        case 'BOOKED':
          return 'info'
        case 'FULFILLED':
          return 'success'
        case 'CANCELLED':
          return 'danger'
        default:
          return 'info'
      }
    },
    statusToText (status) {
      return capitalizeFirst(status.replace(/_/g, ' '))
    },
    toggleDatePickerPanel (event) {
      this.$refs.datePickerPanel.toggle(event)
    },


    // Appointment Editing
    openEditForm (appointmentId) {
      this.showLogActivityModal = true
      this.appointmentToEdit = this.appointments.find(a => a.id === appointmentId)
      this.tempSiteId = this.appointmentToEdit.tenants[0]
    },
    onFormHidden () {
      this.showLogActivityModal = false
      this.appointmentToEdit = null
      this.tempSiteId = null
    },
    async updateAppointmentStatus ({ appointmentId, status }) {
      try {
        let appointmentData = { status };
        const appointment = this.appointmentById(appointmentId);

        if (status === 'FULFILLED' && moment(appointment.startTime).isAfter(moment())) {
          appointmentData.startTime = moment().toDate();
        }
        await this.updateAppointment({ appointmentId, appointmentData });
        this.$toast.add({ severity: 'success', summary: 'Success', detail: `Appointment ${status}`, life: 3000 });
      }
      catch (e) {
        console.log(e)
      }
    },

    // Other Utility Methods
    getSiteByAppointment (appointment) {
      return this.getSiteByShortName(appointment.tenantsPii[0]?.shortName)
    },
    getSiteProfileIdByAppointment (appointment) {
      return appointment.principalPii?.siteProfileIds?.[0] || null;
    },
    lastUpdatedDateFromNow () {
      return this.lastUpdatedDate ? moment(this.lastUpdatedDate).fromNow() : null;
    },
    navigateToPatient (appointment) {
      this.$router.push({ path: `/site/${slugFromSite(site)}/patient/${appointment.principalPii.id}` })
    },
    getName (assignedPractitionerPii) {
      return [assignedPractitionerPii?.firstName, assignedPractitionerPii?.lastName].filter(Boolean).join(' ')
    },
    checkFocusItem (focusItem) {
      if (focusItem.sectionId === 'appointments-table') {
        focusItem.item ? this.expandedRows = [this.appointmentById(focusItem.item.id)] : this.expandedRows = []
      }
    }
  },
  watch: {
    dateFilter: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.loadAppointments()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
</style>