<template>
  <progress-container :loading="loading">
    <slot/>
  </progress-container>
</template>

<script>
  import LoaderMixin from "./LoaderMixin.vue";
  import ProgressContainer from "@/components/progress/ProgressContainer.vue";
  import PortalComponentMixin from '@/mixins/PortalComponentMixin';

  import './SelectDataLoader.scss';

  let siteByIdLoadPromise = false;
  let sitesLoadPromise = false;
  let myUsersLoadPromise = false;

  export default {
    components: {ProgressContainer},
    mixins: [LoaderMixin, PortalComponentMixin],
    props: {
      loadSiteId: {
        type: String,
        default: undefined
      },
      loadSites: {
        type: Boolean,
        default: false
      },
      loadMyUsers: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      /**
       * This is the implementation of LoaderMixin::load()
       * @returns {Promise<unknown[]>}
       */
      load() {
        const promises = [];

        let siteByIdPromise = this.loadSiteByIdCall();
        let sitesPromise = this.loadSitesCall();
        let myUsersPromise = this.loadMyUsersCall();

        if (siteByIdPromise) promises.push(siteByIdPromise);
        if (sitesPromise) promises.push(sitesPromise);
        if (myUsersPromise) promises.push(myUsersPromise);

        return Promise.all(promises);
      },
      // TODO we need to get a backend endpoint for this
      loadSiteByIdCall() {
        if (this.$store.state.portal.sites.length) {
          return Promise.resolve();
        }

        if (this.loadSiteId && !siteByIdLoadPromise) {
          return siteByIdLoadPromise = this.$apiv2.getSiteById(this.loadSiteId).then(result => {
            // We just pretend like we have one site for this view
            this.commit('portal', 'setSites', [result.data]);
            siteByIdLoadPromise = undefined;
          });
        }
      },
      async loadSitesCall() {
        if (this.$store.state.portal.sites.length) {
          this.$emit('sitesLoaded', this.$store.state.portal.sites);
          return;
        }

        if (this.loadSites && !sitesLoadPromise) {
          const sitesRes = await this.$apiv2.getSites();
          sitesRes.data.forEach(async (site) => {
            const siteUsersRes = await this.$apiv2.getSiteUsers(site.id);
            site.users = siteUsersRes.data;
          });
          this.commit('portal', 'setSites', sitesRes.data);
          this.$emit('sitesLoaded', sitesRes.data);
        }
      },
      loadMyUsersCall() {
        if (this.$store.state.portal.myUsers.length) {
          return Promise.resolve();
        }

        if (this.loadMyUsers && !myUsersLoadPromise) {
          return myUsersLoadPromise = this.$apiv2.getMyUsers().then(result => {
            this.commit('portal', 'setMyUsers', result.data.users);
            myUsersLoadPromise = false;
          });
        }
      }
    },
    watch: {
      '$store.state.portal.usersInvalidated': function(value) {
        if (value) this.reload();
      },
      '$store.state.portal.sitesInvalidated': function(value) {
        if (value) this.reload();
      }
    }
  };
</script>
