import PortalComponentMixin from "@/mixins/PortalComponentMixin";

const PortalPageMixin = {
  methods: {
    ...PortalComponentMixin.methods,
  },
  computed: {
    ...PortalComponentMixin.computed
  },
  watch: {
    ...PortalComponentMixin.watch,
    /**
     * This is probably not ideal, but this basically listens for when the site is set on the store,
     * and then updates the auth-nuxt cookie for expiration to what the sites setting is.
     *
     * THIS CANNOT BV MOVED TO THE setSite MUTATION IN THE STORE. THe reason is that Nuxt + Vuex is
     * sometimes just dumb and you can't access the $auth thing we need for setLogoutTimeout from within a
     * mutation.
     */
    "$store.state.portal.site": function(site) {
      if (site && site.feature_values.portal_session_timeout_s) {
        const seconds = parseInt(site.feature_values.portal_session_timeout_s);

        this.setLogoutTimeout(seconds);
      }
    }
  }
}

export default PortalPageMixin;