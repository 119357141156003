import apiv2 from '@/api';
import moment from "moment";
//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  filterViews: null,
  selectedFilterView: null,
  filtersChanged: false,
  patientJourneyStatuses: [],
  numberOfPatients: 0
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  SET_SITE_FILTERS (state, filters) {
    state.filterViews = filters
  },
  SET_SELECTED_FILTER_VIEW (state, filters) {
    state.selectedFilterView = filters
  },
  SET_FILTERS_CHANGED_FLAG (state, flag) {
    state.filtersChanged = flag
  },
  SET_PATIENT_JOURNEY_STATUSES (state, statuses) {
    state.patientJourneyStatuses = statuses
  },
  SET_NUMBER_OF_PATIENTS (state, numberOfPatients) {
    state.numberOfPatients = numberOfPatients
  }
};

export const actions = {
  async fetchSiteFilters ({ commit }, { siteUser }) {
    try {
      const response = await apiv2.getFilterViews(siteUser)
      commit('SET_SITE_FILTERS', response.data)
      commit('SET_FILTERS_CHANGED_FLAG', false)
    }
    catch (e) {
      console.log(e)
      commit('SET_SITE_FILTERS', {})
      commit('SET_FILTERS_CHANGED_FLAG', false)
      throw e
    }
  },
  async createFilterView ({ dispatch, state, commit }, { siteUserID, filterView }) {
    try {
      const response = await apiv2.createFilterView(siteUserID, filterView)
      commit('SET_SITE_FILTERS', response.data.filter_views)
      dispatch('setFilterViewByName', filterView.name)
    } catch (e) {
      console.log(e)
      dispatch('fetchSiteFilters', { siteUser: siteUserID })
      throw e;
    }
  },
  async updateFilterView ({ dispatch, commit }, { siteUserID, filterView }) {
    try {
      const response = await apiv2.updateFilterView(siteUserID, filterView)
      commit('SET_SITE_FILTERS', response.data.filter_views)
      dispatch('setFilterViewByName', filterView.name)
    } catch (e) {
      dispatch('fetchSiteFilters', { siteUser: siteUserID })
      throw e;
    }
  },
  async fetchPatientJourneyStatuses ({ commit }) {
    try {
      const response = await apiv2.getPatientJourneyStatuses()
      commit('SET_PATIENT_JOURNEY_STATUSES', response)
    } catch (e) {
      console.log(e)
      commit('SET_PATIENT_JOURNEY_STATUSES', [])
      throw e;
    }
  },
  setFilterViewByName ({ state, commit }, filterViewName) {
    let newFilterView = state.filterViews.find(filter => filter.name === filterViewName)
    commit('SET_SELECTED_FILTER_VIEW', newFilterView)
    commit('SET_FILTERS_CHANGED_FLAG', false)
  },
  setSelectedFilterView ({ commit }, data) {
    commit('SET_SELECTED_FILTER_VIEW', data)
  },
  addNewFilter ({ commit, state }, filter) {
    // Make copy of object
    let newSelectedView = state.selectedFilterView ? JSON.parse(JSON.stringify(state.selectedFilterView)) : { filter: {} }
    newSelectedView.filter[filter.columnID] = filter.value;
    commit('SET_SELECTED_FILTER_VIEW', newSelectedView)
    commit('SET_FILTERS_CHANGED_FLAG', true)
  },
  removeFilter ({ commit, state }, columnID) {
    // Make copy of object
    let newSelectedView = JSON.parse(JSON.stringify(state.selectedFilterView))
    delete newSelectedView.filter[columnID];
    commit('SET_SELECTED_FILTER_VIEW', newSelectedView)
    commit('SET_FILTERS_CHANGED_FLAG', true)
  },
  resetFilterView ({ commit, state }) {
    let defaultFilterView = state.filterViews.find(filter => filter.id === state.selectedFilterView.id)
    commit('SET_SELECTED_FILTER_VIEW', defaultFilterView)
    commit('SET_FILTERS_CHANGED_FLAG', false)
  },
  unselectFilterView ({ commit }) {
    commit('SET_SELECTED_FILTER_VIEW', { filter: {} })
  },
  async deleteFilterView ({ commit, state, dispatch }, { siteUserID, filterViewID }) {
    try {
      const response = await apiv2.deleteFilterView(siteUserID, filterViewID)
      commit('SET_SITE_FILTERS', response.data.filter_views)
      dispatch('unselectFilterView')
    } catch (e) {
      dispatch('fetchSiteFilters', { siteUser: siteUserID })
      throw e;
    }
  },
  setNumberOfPatients ({ commit }, numberOfPatients) {
    commit('SET_NUMBER_OF_PATIENTS', numberOfPatients)
  }
}

export const getters = {
  getFilterViewFilters: (state) => {
    let filters = state.selectedFilterView && state.selectedFilterView.filter
    if (!filters) return null
    const computedFilterKeys = ['last-session-date', 'spo2-timestamp', 'mmrc-timestamp', 'eligibility-start', 'eligibility-end', 'last-communication', 'last-comm-date']
    let computedFilters = {}
    Object.keys(filters).forEach((key) => {
      let filter = filters[key]
      if (computedFilterKeys.includes(key)) {
        filter.dateTo = moment().format('YYYY-MM-DD')
        switch (filter.timespan) {
          case 'LAST_DAY':
            filter.dateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD')
            break
          case 'LAST_7_DAYS':
            filter.dateFrom = moment().subtract(7, 'days').format('YYYY-MM-DD')
            break
          case 'LAST_30_DAYS':
            filter.dateFrom = moment().subtract(30, 'days').format('YYYY-MM-DD')
            break
          case 'LAST_90_DAYS':
            filter.dateFrom = moment().subtract(90, 'days').format('YYYY-MM-DD')
            break
          case 'LAST_6_MONTHS':
            filter.dateFrom = moment().subtract(6, 'months').format('YYYY-MM-DD')
            break
          case 'LAST_YEAR':
            filter.dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD')
            break
          case 'LAST_2_YEARS':
            filter.dateFrom = moment().subtract(2, 'years').format('YYYY-MM-DD')
            break
        }
      }
      computedFilters[key] = filter
    })
    return computedFilters
  },
  isFiltersApplied: (state) => state.selectedFilterView && Object.keys(state.selectedFilterView.filter || {}).length > 0,
  isFilterViewSelected: (state) => state.selectedFilterView && state.selectedFilterView.id,
}