// src/store/auth.ts
import axios from "@/plugins/axios";
import authManager from "@/auth/AuthManager";
import { User } from '@/interfaces/user.interface'

interface AuthState {
    isLoggedIn: boolean;
    user: User | null;
    twoFAToken: string | null;
}

export default {
    namespaced: true,
    state: {
        isLoggedIn: authManager.isLoggedIn,
        user: null,
        twoFAToken: null,
    },
    mutations: {
        setLoggedIn(state: AuthState, loggedIn: boolean) {
            state.isLoggedIn = loggedIn;
        },
        setUser(state: AuthState, user: User | null) {
            state.user = user;
        },
        setTwoFAToken(state: AuthState, token: string | null) {
            state.twoFAToken = token;
        },
    },
    actions: {
        async bootstrap({ dispatch }) {
            if (authManager.isLoggedIn) {
                await dispatch("fetchUser");
            }
        },
        async fetchUser({ commit }) {
            try {
                const response = await axios.get("/api/portal_admin/me");
                console.log(response.data)
                commit("setUser", response.data.me);
                return response.data.me;
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        },
        async login({ commit, dispatch }, { username, password }) {
            try {
                const response = await axios.post("/api/login", { username, password });

                if (response.data.type === "twofa") {
                    commit("setTwoFAToken", response.data.token);
                } else {
                    authManager.setLoggedIn(true, response.data.token);
                    commit("setLoggedIn", true);
                    await dispatch("fetchUser");
                }

                return response;
            } catch (error) {
                authManager.setLoggedIn(false);
                commit("setLoggedIn", false);
                throw error;
            }
        },
        async verifyTwoFA({ commit, dispatch, state }, { code }) {
            try {
                const response = await axios.post(
                    "/api/auth/tokens/2fa/sms/verifications/",
                    { code },
                    {
                        headers: {
                            Authorization: `Bearer ${state.twoFAToken}`,
                        },
                    }
                );

                authManager.setLoggedIn(true, response.data.access_token);
                commit("setLoggedIn", true);
                commit("setTwoFAToken", null);
                await dispatch("fetchUser");

                return response;
            } catch (error) {
                commit("setTwoFAToken", null);
                throw error;
            }
        },
        logout({ commit }) {
            authManager.setLoggedIn(false);
            commit("setLoggedIn", false);
            commit("setUser", null);
        },
    },
    getters: {
        user: (s) => s.user,
    }
}
