import {
  TIMELINE_SOURCE_TAG_SPIROMETRY,
  TIMELINE_SOURCE_TAGS,
  DATE_RANGE_NAMES,
  TIMELINE_SET_PROFILE,
  TIMELINE_SET_CURSOR_DATE,
  TIMELINE_SET_SEL_SPIROMETRY_SESSION,
  TIMELINE_LAST_SPIROMETRY_SESSION,
  TIMELINE_NEXT_SPIROMETRY_SESSION,
  TIMELINE_PREV_SPIROMETRY_SESSION,
  TIMELINE_FIRST_SPIROMETRY_SESSION,
  TIMELINE_SET_DATE_RANGE,
  TIMELINE_SET_SHOW_CUSTOM_DATE_RANGE_PICKER,
  TIMELINE_SET_SHOW_GRID_BY_SOURCE_TAG,
  TIMELINE_FORCE_REFRESH_BY_SOURCE_TAG,
  TIMELINE_SET_DATA_BY_SOURCE_TAG,
  TIMELINE_SET_LOADING_BY_SOURCE_TAG,
  TIMELINE_CLEAR_DATA_BY_SOURCE_TAG,
  TIMELINE_SET_SITE_PROFILE,
  TIMELINE_SET_SHOW_REPORTS
}
  from "@/components/timeline/TimelineConstants";

//--------------------------------------------------------------------------------------------------------
// LocalStorage
//--------------------------------------------------------------------------------------------------------
function getFiltersFromLocalStorage() {
  try {
    const toLoad = JSON.parse(window.localStorage.getItem('timeline-filters'));

    return {
      rangeName: toLoad.rangeName,
      startDate: toLoad.startDateTs ? new Date(toLoad.startDateTs) : undefined,
      endDate: toLoad.endDateTs ? new Date(toLoad.endDateTs) : undefined
    }
  } catch(error) {
    console.error('Could not load filters from local storage. Resetting to defaults.', error);

    return {
      rangeName: undefined,
      startDate: new Date(1970, 0, 0),
      endDate: new Date()
    };
  }
}

function setFiltersOnLocalStorage(filters) {
  try {
    const toSave = {
      rangeName: filters.rangeName,
      startDateTs: filters.startDate ? filters.startDate.getTime() : undefined,
      endDateTs: filters.endDate ? filters.endDate.getTime() : undefined
    };

    window.localStorage.setItem('timeline-filters', JSON.stringify(toSave));
  } catch (error) {
    console.error('Error saving filters to local storage', error);
  }
}

//--------------------------------------------------------------------------------------------------------
// Default State
//--------------------------------------------------------------------------------------------------------
export const state = () => {
  const timelineSourcesByTag = {};

  /**
   * Even though we have a TimelineSourceBase extended model for each timeline source,
   * we store properties we want to watch in the view here as well just for reactivity.
   */
  TIMELINE_SOURCE_TAGS.forEach(sourceTag => {
    timelineSourcesByTag[sourceTag] = {
      loading: false,
      forceRefreshKey: 0, // Increment this to force a refresh of that view
      data: [],
      showGrid: false
    }
  });

  // Try to load the filters from LocalStorage
  let filters = getFiltersFromLocalStorage();

  return {
    profile: undefined,
    showCustomDateRangePicker: false,
    filters,
    cursorDate: undefined,
    selSpirometrySession: undefined,
    reloadSpirometryTimeline: false,
    refreshSpirometryDrawerCounter: 0,
    timelineSourcesByTag,
    showReports: false
  }
};

//--------------------------------------------------------------------------------------------------------
// Mutations
//--------------------------------------------------------------------------------------------------------
const timeline_setDateRange = function(state, { rangeName = 'default', startDate, endDate }) {
  let d = new Date();
  if (![DATE_RANGE_NAMES.ALL, DATE_RANGE_NAMES.ONE_WEEK, DATE_RANGE_NAMES.ONE_MONTH, DATE_RANGE_NAMES.THREE_MONTHS,
    DATE_RANGE_NAMES.SIX_MONTHS, DATE_RANGE_NAMES.ONE_YEAR, DATE_RANGE_NAMES.CUSTOM, DATE_RANGE_NAMES.DEFAULT].includes(rangeName)) {
    throw new Error(`Timeline store: ${rangeName} is not a valid date range name.`);
  }

  if (rangeName === DATE_RANGE_NAMES.ONE_WEEK) {
    d.setDate(d.getDate() - 7);
  } else if (rangeName === DATE_RANGE_NAMES.ONE_MONTH) {
    d.setMonth(d.getMonth() - 1);
  } else if (rangeName === DATE_RANGE_NAMES.THREE_MONTHS) {
    d.setMonth(d.getMonth() - 3);
  } else if (rangeName === DATE_RANGE_NAMES.SIX_MONTHS) {
    d.setMonth(d.getMonth() - 6);
  } else if (rangeName === DATE_RANGE_NAMES.ONE_YEAR) {
    d.setMonth(d.getMonth() - 12);
  } else if (rangeName === DATE_RANGE_NAMES.CUSTOM) {
    state.filters = {
      rangeName,
      startDate,
      endDate
    };

    setFiltersOnLocalStorage(state.filters);

    return;
  } else if (!rangeName || rangeName === DATE_RANGE_NAMES.DEFAULT) {
    rangeName = DATE_RANGE_NAMES.THREE_MONTHS;
    d.setMonth(d.getMonth() - 3);
  } else if (rangeName === DATE_RANGE_NAMES.ALL) {
    const dateProfileCreated = new Date(Date.parse(state.profile.created_at));
    dateProfileCreated.setMonth(dateProfileCreated.getMonth() - 1);
    d = dateProfileCreated;
  }

  const endOfToday = new Date();
  endOfToday.setHours(23);
  endOfToday.setMinutes(59);
  endOfToday.setSeconds(59);

  state.filters = {
    rangeName,
    startDate: d,
    endDate: endOfToday
  };

  setFiltersOnLocalStorage(state.filters);
}

function adjustSelectedDateRangeToSelectedSpiroSession(state) {
  if (state.selSpirometrySession) {
    const s = state.filters.startDate.getTime();
    const e = state.filters.endDate.getTime();
    const spiroTime = state.selSpirometrySession.created_at.getTime();

    if (spiroTime < s || spiroTime > e) {
      // Adjust to one month around the spirometry time
      const newStartDate = new Date(spiroTime);
      newStartDate.setMonth(newStartDate.getMonth() - 1);
      const newEndDate = new Date(spiroTime);
      newEndDate.setMonth(newEndDate.getMonth() + 1);

      state.filters = {
        rangeName: DATE_RANGE_NAMES.CUSTOM,
        startDate: newStartDate,
        endDate: newEndDate
      };
    }
  }
}

export const mutations = {
  [TIMELINE_SET_SHOW_REPORTS]: function(state, show) {
    state.showReports = show;
  },
  [TIMELINE_SET_PROFILE]: function(state, profile) {
    state.profile = profile;

    // Everytime the profile changes, we update the date range to the default
    const filters = getFiltersFromLocalStorage();

    timeline_setDateRange(state, filters);
  },
  [TIMELINE_SET_SITE_PROFILE]: function(state, siteProfile) {
    state.siteProfile = siteProfile;
  },
  [TIMELINE_SET_CURSOR_DATE]: function(state, cursorDate) {
    state.cursorDate = cursorDate;
  },
  [TIMELINE_SET_SEL_SPIROMETRY_SESSION]: function(state, selSpirometrySession) {
    state.selSpirometrySession = selSpirometrySession;

    adjustSelectedDateRangeToSelectedSpiroSession(state);
  },
  [TIMELINE_CLEAR_DATA_BY_SOURCE_TAG]: function (state, sourceTag) {
    state.timelineSourcesByTag[sourceTag].data = [];
  },
  [TIMELINE_SET_SHOW_GRID_BY_SOURCE_TAG]: function (state, { sourceTag, showGrid }) {
    state.timelineSourcesByTag[sourceTag].showGrid = showGrid;
  },
  [TIMELINE_FORCE_REFRESH_BY_SOURCE_TAG]: function (state, sourceTag) {
    // lol
    state.timelineSourcesByTag[sourceTag].forceRefreshKey++;
  },
  [TIMELINE_SET_DATA_BY_SOURCE_TAG]: function (state, { sourceTag, data }) {
    state.timelineSourcesByTag[sourceTag].loading = false;
    state.timelineSourcesByTag[sourceTag].data = data;
  },
  [TIMELINE_SET_LOADING_BY_SOURCE_TAG]: function (state, sourceTag) {
    state.timelineSourcesByTag[sourceTag].loading = true;
  },
  [TIMELINE_PREV_SPIROMETRY_SESSION]: function(state) {
    const { selSpirometrySession } = state;
    const sessions = state.timelineSourcesByTag[TIMELINE_SOURCE_TAG_SPIROMETRY].data;

    if (selSpirometrySession) {
      const ix = sessions.findIndex(s => s.id === selSpirometrySession.id);

      if (ix > 0) {
        state.selSpirometrySession = sessions[ix - 1];
      }
    } else if (sessions.length) {
      state.selSpirometrySession = sessions[sessions.length - 1];
    }

    adjustSelectedDateRangeToSelectedSpiroSession(state);
  },
  [TIMELINE_NEXT_SPIROMETRY_SESSION]: function(state) {
    const { selSpirometrySession } = state;
    const sessions = state.timelineSourcesByTag[TIMELINE_SOURCE_TAG_SPIROMETRY].data;

    if (selSpirometrySession) {
      const ix = sessions.findIndex(s => s.id === selSpirometrySession.id);

      if (ix < sessions.length - 1) {
        state.selSpirometrySession = sessions[ix + 1];
      }
    } else if (sessions.length) {
      state.selSpirometrySession = sessions[0];
    }

    adjustSelectedDateRangeToSelectedSpiroSession(state);
  },
  [TIMELINE_FIRST_SPIROMETRY_SESSION]: function(state) {
    const sessions = state.timelineSourcesByTag[TIMELINE_SOURCE_TAG_SPIROMETRY].data;

    if (sessions.length) {
      state.selSpirometrySession = sessions[0];
    }

    adjustSelectedDateRangeToSelectedSpiroSession(state);
  },
  [TIMELINE_LAST_SPIROMETRY_SESSION]: function(state) {
    const sessions = state.timelineSourcesByTag[TIMELINE_SOURCE_TAG_SPIROMETRY].data;

    if (sessions.length) {
      state.selSpirometrySession = sessions[sessions.length - 1];
    }

    adjustSelectedDateRangeToSelectedSpiroSession(state);
  },
  [TIMELINE_SET_DATE_RANGE]: timeline_setDateRange,
  [TIMELINE_SET_SHOW_CUSTOM_DATE_RANGE_PICKER]: function (state, show) {
    state.showCustomDateRangePicker = show;
  },

  // Reload timeline
  // TODO this should be turned into a Vuex action or something equivalent.
  requestReloadSpirometryTimeline: function(state) {
    state.reloadSpirometryTimeline = true;
  },
  spirometryTimelineReloaded: function(state) {
    state.reloadSpirometryTimeline = false;
  },
  forceUpdateSpirometryDrawer: function(state) {
    state.refreshSpirometryDrawerCounter += 1;
  }
};