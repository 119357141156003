//----------------------------------------------------------------------------
// Ignore this file for now, i will come back to it once the backend is working
//----------------------------------------------------------------------------

//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  asthmaReport: undefined
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  loadAsthmaReport (state) {
    let asthmaReportsJSON = JSON.parse(window.localStorage.getItem('asthmaReports'))
    asthmaReportsJSON.forEach(id => {
      asthmaReport.push(asthmaReportsJSON[id])
    })

  }
};