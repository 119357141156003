<template>
    <div>
        <div v-if="!isFulfilledAppointment && !isCancelledAppointment && !isNoShowAppointment"
            class="flex justify-content-between">
            <div>
                <Button label="Cancel" icon="pi pi-times" class="p-button-danger p-button-sm" @click="cancelAppointment" />
                <Button v-if="isAppointmentInPast" label="No show" icon="pi pi-phone"
                    class="p-button-danger p-button-sm p-button-outlined" @click="markNoShowAppointment" />
            </div>
            <div>
                <Button v-if="adverseEvent && inProfileView" class="p-button-sm p-button-text" @click="goToCareEvent">
                    Go to care event
                </Button>
                <Button label="Update" icon="pi pi-pencil" class="p-button-sm p-button-outlined" @click="editAppointment" />
                <Button label="Fulfill" icon="pi pi-check-circle" class="p-button-success p-button-sm ml-2"
                    @click="fulfillAppointment" />
            </div>
        </div>
        <div v-else class="flex justify-content-end">
            <Button v-if="adverseEvent && inProfileView" class="p-button-sm p-button-text" @click="goToCareEvent">
                Go to care event
            </Button>
            <CommunicationLogForm v-model:showLogActivityModal="showLogActivityModal" prefill :logToEdit="appointmentToLog" />
        </div>
        <LogNotes :logId="appointmentId" :notes="appointment.notes" @add-note="onNoteAdd" @update-note="onNoteUpdate"
            @delete-note="onNoteDelete" />
    </div>
</template>
  
<script>
import LogNotes from '@/components/common/LogNotes.vue'
import CommunicationLogForm from '@/components/communication/logs/CommunicationLogForm.vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
const appointmentsVuex = createNamespacedHelpers('appointments')
const profileVuex = createNamespacedHelpers('profile')

export default {
    components: {
        CommunicationLogForm,
        LogNotes
    },
    props: {
        appointment: {
            type: Object,
            default: () => ({})
        },
        inProfileView: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showLogActivityModal: false,
            appointmentToLog: null
        }
    },
    computed: {
        isPendingAppointment () {
            return this.appointment.status === 'PENDING'
        },
        isBookedAppointment () {
            return this.appointment.status === 'BOOKED'
        },
        isFulfilledAppointment () {
            return this.appointment.status === 'FULFILLED'
        },
        isCancelledAppointment () {
            return this.appointment.status === 'CANCELLED'
        },
        isNoShowAppointment () {
            return this.appointment.status === 'NO_SHOW'
        },
        appointmentId () {
            return this.appointment.id
        },
        isAppointmentInPast () {
            return moment(this.appointment.startTime).isBefore(moment())
        },
        adverseEvent () {
            return this.appointment.adverseEvent
        }
    },
    methods: {
        ...profileVuex.mapActions(['setFocus']),
        ...appointmentsVuex.mapActions(['addNoteToAppointment', 'updateNoteOnAppointment', 'deleteNoteOnAppointment']),
        onNoteAdd ({ logId, notePayload }) {
            this.addNoteToAppointment({ appointmentId: logId, notePayload })
        },
        onNoteUpdate (payload) {
            this.updateNoteOnAppointment(payload)
        },
        onNoteDelete (payload) {
            this.deleteNoteOnAppointment(payload)
        },
        editAppointment () {
            this.$emit('edit-appointment', this.appointmentId)
        },
        updateAppointmentStatus (status) {
            this.$emit('update-appointment-status', { appointmentId: this.appointmentId, status })
        },
        markNoShowAppointment () {
            this.updateAppointmentStatus('NO_SHOW')
        },
        cancelAppointment () {
            this.updateAppointmentStatus('CANCELLED')
        },
        fulfillAppointment () {
            this.updateAppointmentStatus('FULFILLED')
        },
        goToCareEvent () {
            this.setFocus({ sectionId: 'care-events-table', item: this.adverseEvent })
        }
    },
    watch: {
        showLogActivityModal (val) {
            if (val) {
                this.appointmentToLog = {
                    sent: this.appointment.startTime || moment().toDate(),
                    medium: this.appointment.medium,
                    purpose: this.appointment.purpose,
                    notes: this.appointment.notes,
                }
            }
            else {
                this.appointmentToLog = null
            }
        }
    }
}
</script>
  
<style lang="scss" scoped>
.notes-wrapper {
    margin: 8px 0;
    background-color: #f7f7f7;
    border-radius: 4px;
}

.note-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;

    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }

    &__top {
        display: flex;

        &__notes {
            flex: 1;

            // TODO: to be removed after Vuetify is removed
            :deep ul {
                padding-inline-start: 40px;
            }
        }

        &__ctas {
            display: flex;
            justify-content: space-between;
            align-items: center;

            * {
                margin-left: 8px;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 11px;
        color: #999;
    }
}
</style>