<template>
  <Dialog :header="headerText" v-model:visible="showModal" :modal="true" :width="600" dismissableMask @hide="$emit('hide')"
    @show="$emit('show')">
    <LogActivityForm :pastActivity="pastActivity" :siteId="siteId" :prefill="prefill" :logToEdit="log" :disabledFields="disabledFields" />
    <template #footer>
      <Button label="Cancel" @click="onCancel" />
      <Button :label="submitButtonText" :disabled="!canSubmit" @click="onSubmit" />
    </template>
  </Dialog>
</template>
  
<script>
import LogActivityForm from './LogActivityForm.vue';
import moment from 'moment';

const initLog = {
  logDate: moment().toDate(),
  purpose: null,
  direction: 'OUTBOUND',
  medium: 'VERBAL_PHONE',
  communicationResult: null,
  assignedPractitioner: null,
  subject: null,
  payload: null,
  duration: null,
  notes: null,
}

export default {
  components: {
    LogActivityForm,
  },
  data () {
    return {
      showModal: false,
      log: { ...initLog },
      showNotes: false,
    }
  },
  props: {
    showLogActivityModal: {
      type: Boolean,
      default: false,
    },
    siteId: {
      type: String,
      required: false
    },
    pastActivity: {
      type: Boolean,
      default: false,
    },
    logToEdit: {
      type: Object,
      default: null,
    },
    prefill: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.showModal = this.showLogActivityModal
  },
  computed: {
    mediumKey () {
      return this.log.medium
    },
    isSMS () {
      return this.mediumKey === 'WRITTEN_SMS'
    },
    canSubmit () {
      const canSubmitForm = this.log.purpose && this.mediumKey && this.log.logDate && ((!this.pastActivity && this.log.assignedPractitioner) || this.log.direction && this.log.communicationResult && (this.isSMS ? this.log.payload : this.log.subject && this.log.duration))
      return !!canSubmitForm
    },
    isEditMode () {
      return !!this.logToEdit && !this.prefill
    },
    headerText () {
      const headerText = this.pastActivity ? 'Log Activity' : 'Appointment'
      return (this.isEditMode ? 'Edit' : 'Add') + ' ' + headerText
    },
    submitButtonText () {
      return this.isEditMode ? 'Update' : 'Save'
    },
  },
  methods: {
    resetForm () {
      this.log = { ...initLog }
      this.showNotes = false
    },
    onSubmit () {
      const logToSubmit = {
        purpose: this.log.purpose,
        direction: this.log.direction,
        medium: this.log.medium,
      }

      if (this.pastActivity) {
        logToSubmit.sent = this.log.logDate
        logToSubmit.communicationResult = this.log.communicationResult;
        if (this.isSMS) {
          logToSubmit.payload = this.log.payload
        } else {
          logToSubmit.subject = this.log.subject
          logToSubmit.encounter = {
            durationInSeconds: this.log.duration * 60,
          }
        }
      }
      else {
        logToSubmit.startTime = this.log.logDate
        logToSubmit.assignedPractitioner = this.log.assignedPractitioner
      }

      if (this.isEditMode) {
        this.$emit('update', { logId: this.log.id, logData: logToSubmit })
      }
      else {
        // * set a default value to PENDING
        if (!this.pastActivity) {
          logToSubmit.status = 'PENDING'
        }
        if (this.prefill) {
          if (this.log.notes) {
            logToSubmit.notes = this.log.notes
          }
        } else {
          if (this.log.notes) {
            logToSubmit.notes = [{ text: this.log.notes }]
          }
        }
        this.$emit('save', logToSubmit)
      }
    },
    onCancel () {
      this.resetForm()
      this.$emit('hide')
    },
  },
  watch: {
    showLogActivityModal (val) {
      this.showModal = val
      if (!val) {
        this.resetForm()
      }
    },
    logToEdit (val) {
      console.log('logToEdit', val)
      if (val) {
        this.log = { ...val, logDate: this.pastActivity ? new Date(val.sent) : new Date(val.startTime) }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.log-activity-form {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 500px;
  margin: 0 auto;
  width: 33vw;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.radio-group {
  display: flex;
  margin-top: 8px;

  div {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 8px 0 4px;
    font-weight: bold;
    align-self: flex-start;
  }
}
</style>