import { asthmaReportPatchSerialize, asthmaReportSerialize } from "@/utils/asthmaReportUtil";
import axios from "@/plugins/axios";
import { TokenType } from "./constants";
import { FilterMatchMode } from 'primevue/api';

export default class APIV2Adapter {
  /**
   * The two-factor token is stored here only temporarily.
   *
   * The actual token is stored via the nuxt-auth module. See the login.vue.
   */
  twoFactorToken = undefined;

  login (username, password) {
    return axios.post('/gateway/services/auth-service/authenticate', {
      username, password
    }, undefined, (method, url, error) => {
      if (error.response.status >= 400 || error.response.status <= 499) {
        // In this case we ignore the default error handlers handling of 401 or 403
        // and let the LoginForm display a message about invalid login.
        throw error;
      }

      // Go to default error handler for all other errors.
      this.errorHandler(method, url, error);
    }).then(result => {
      if (result.data.token_type === TokenType.TWOFACTOR) {
        this.twoFactorToken = result.data.access_token;
      }
      return result;
    });
  }

  loginTwoFactorCode (code) {
    return axios.post('/gateway/services/auth-service/2fa/sms/verify', {
      code
    }, {
      'Authorization': `Bearer ${this.twoFactorToken}`
    });
  }

  loginSendTwoFactorCode () {
    return axios.post('/gateway/services/auth-service/2fa/sms/request', {}, {
      'Authorization': `Bearer ${this.twoFactorToken}`
    });
  }

  // logout(){
  //   return axios.delete('api/auth/tokens/')
  // }

  getMe () {
    return axios.get('/gateway/services/apiv2/portal_admin/me');
  }

  getPortalSettings () {
    return axios.get('/gateway/services/apiv2/portal_admin/settings');
  }

  getSites (page = 0, size = 100) {
    return axios.get(`/gateway/services/patient-hub/sites/?page=${page}&size=${size}`);
  }

  getSiteUsers (siteId) {
    return axios.get(`/gateway/services/patient-hub/sites/${siteId}/users`);
  }

  getSiteById (id) {
    return axios.get(`/gateway/services/apiv2/sites/${id}`);
  }

  getMyUsers () {
    return axios.get('/gateway/services/apiv2/portal_admin/my/users/');
  }

  postUser (body) {
    return axios.post('/gateway/services/apiv2/portal_admin/user/', body);
  }

  patchUser (user) {
    return axios.patch('/gateway/services/apiv2/portal_admin/user/', user);
  }

  getUser (userId) {
    return axios.get(`/gateway/services/apiv2/portal_admin/user/${userId}`);
  }

  passwordReset (body) {
    // Don't use gateway here, apiv2 cannot properly serve this call via gateway
    return axios.post('/password/reset/', body);
  }

  forgotPasswordSendEmail (email) {
    // Don't use gateway here, apiv2 cannot properly serve this call via gateway
    const body = { email };
    return axios.post('/api/auth/password/reset/', body);
  }

  getProfileAggregatesBySiteId (siteId) {
    return axios.get(`/gateway/services/patient-hub/profiles/aggregates?site_id=${siteId}`);
  }

  getProfileById (profileId) {
    return this.GET(`/gateway/services/patient-hub/api/profiles/${profileId}`);
  }

  async searchProfiles({firstName, lastName, sort, size=1000}) {
    const queryParams = [];
    if (sort) {
      queryParams.push(`sort=${sort}`);
    }
    if (firstName) {
      queryParams.push(`firstName.contains=${firstName}`);
    }
    if (lastName) {
      queryParams.push(`lastName.contains=${lastName}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    let queryParamsString = queryParams.join('&');
    const response = await this.GET(`/gateway/services/patient-hub/api/profiles?${queryParamsString}`);
    return response.data;
  }

  async getSiteProfileDetails (site_profile_id) {
    const response = await axios.get(`/gateway/services/patient-hub/sites/profiles/${site_profile_id}`)
    return response.data;
  }

  async updateProfileConsent (profileId, consents) {
    const response = await axios.post(`/gateway/services/patient-hub/patient-consents/configure?profileId=${profileId}`, consents)
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Messages
  //------------------------------------------------------------------------------------------------------------

  getSMSTemplates (site) {
    return axios.get(`/gateway/services/communication-hub-service/api/v1/site-templates/${site.id}`);
  }

  async send ({ profileId, component = 'unknown-component', phoneNumber = undefined,
    replyEmail = undefined, emailSubject, emailBody, smsBody, appendDoNotReply = true }) {
    const body = {
      profile: profileId,
      source: `portal2.0:${component}`,
      message: {}
    };

    if (smsBody) {
      body.message_type = 'SMS'
      body.message.sms = {
        phone_number: phoneNumber !== '' && phoneNumber !== undefined ? phoneNumber : undefined, // Can be undefined, in which case the profile's phone number is used
        body: smsBody,
        append_do_not_reply: appendDoNotReply
      }
    }

    if (emailBody) {
      body.message_type = 'EMAIL'
      body.message.email = {
        reply_email: replyEmail,   // If undefined, 'NuvoAir no-reply@nuvoair.com' is used.
        subject: emailSubject,
        body: emailBody
      }
    }

    return axios.post(`/gateway/services/communication-hub-service/api/v1/communicationEngine/send/`, body);
  }

  postSendTemplate (site, body, title) {
    return axios.post('/gateway/services/communication-hub-service/api/v1/site-templates/', {
      tenant: site.id,
      body,
      title
    });
  }

  putSendTemplate (template_id, site, body, title) {
    return axios.put(`/gateway/services/communication-hub-service/api/v1/site-templates/${template_id}`, {
      tenant: site.id,
      body,
      title
    });
  }

  deleteSendTemplate (template_id) {
    return axios.delete(`/gateway/services/communication-hub-service/api/v1/site-templates/${template_id}`);
  }

  async getMessageChannels(params) {
    const queries = [];
    Object.entries(params).forEach(([key,val]) => {
      val && queries.push(`${key}=${val}`);
    })
    const queryString = queries.join('&');
    const response = await this.GET(`/gateway/services/messaging-service/api/channels${queryString ? `?${queryString}` : ''}`);
    return response.data;
  }
  
  async getAllMessages(params) {
    const queries = [];
    if (params.principal) {
      queries.push(`principal.equals=${params.principal}`);
    }
    if (params.channel) {
      queries.push(`channelId.equals=${params.channel}`);
    }
    if (params.sort) {
      queries.push(`sort=${params.sort}`);
    }
    if (params.size) {
      queries.push(`size=${params.size}`);
    }
    if (params.lastModifiedDate) {
      queries.push(`lastModifiedDate.greaterThan=${params.lastModifiedDate}`);
    }
    if (params.lastMessage) {
      queries.push(`lastMessage.lastModifiedDate.specified&lastMessage.lastModifiedDate.greaterThan=${params.lastMessage}`);
    }
    const queryString = queries.join('&');
    const response = await this.GET(`/gateway/services/messaging-service/api/messages${queryString ? `?${queryString}` : ''}`);
    return response.data;
  }
  
  async sendMessage (principal, message) {
    const response = await this.POST(`/gateway/services/messaging-service/api/chat/${principal}`, message, {'Content-Type': 'text/plain'});
    return response.data;
  }

  getSiteProfileById (site_profile_id) {
    return axios.get(`/gateway/services/apiv2/sites/profiles/${site_profile_id}`)
  }

  generateSite ({ siteName, siteDescription, profileCount, profileType, shareCode,
    sessionCountMin, sessionCountMax, aosSessionCountMin, aosSessionCountMax }) {
    return axios.post('/gateway/services/apiv2/mock/site/', {
      name: siteName,
      description: siteDescription,
      profiles: {
        count: parseInt(profileCount),
        type: profileType,
        spirometry_sessions: {
          count: {
            min: sessionCountMin,
            max: sessionCountMax
          }
        },
        aos_sessions: {
          count: {
            min: aosSessionCountMin,
            max: aosSessionCountMax
          }
        }
      },
      share_code: shareCode,
      tag: '',
      source: 'portal:2.0'
    })
  }

  //------------------------------------------------------------------------------------------------------------
  // Patient Journey
  //------------------------------------------------------------------------------------------------------------

  async getPatientJourneyStatuses () {
    const response = await axios.get('/gateway/services/patient-hub/journey-statuses');
    return response.data;
  }

  async addPatientJourney (profileId, patientJourney) {
    const response = await axios.post(`/gateway/services/patient-hub/patient-journeys?profileId=${profileId}`, patientJourney);
    return response.data;
  }

  async deletePatientJourney (journeyId, profileId) {
    const response = await axios.delete(`/gateway/services/patient-hub/patient-journeys/${journeyId}?profileId=${profileId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Video Call Stuff
  //------------------------------------------------------------------------------------------------------------
  getOrCreateTwilioVideoRoom (userIds, siteId) {
    return axios.post('/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/', {
      user_ids: userIds,
      site_id: siteId
    });
  }

  /**
   * Note this is used to get a host JWT *or* a patient JWT. The participant IDs are all returned
   * in getOrCreateTwilioVideoRoom.
   *
   * @param participantId
   * @returns {*}
   */
  createTwilioAccessTokenForParticipant (participantId) {
    return axios.post(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}/access_tokens/`);
  }

  sendTwilioVideoRoomInvite ({ participantId, sendSMS, sendEmail, customPhoneNumber = undefined, videoLinkUrl }) {
    return axios.post(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}/invites/`, {
      send_sms: sendSMS,
      send_email: sendEmail,
      phone_number: customPhoneNumber,
      video_link_url: videoLinkUrl
    });
  }

  getTwilioVideoParticipant (participantId) {
    return axios.get(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/participants/${participantId}`);
  }

  getTwilioVideoRoom (videoRoomUniqueName) {
    return axios.get(`/gateway/services/communication-hub-service/api/v1/twilio-video-conference/rooms/${videoRoomUniqueName}`);
  }

  getSpirometrySessionReport ({ spirometrySessionIds, reportName, siteId, format = 'json', blob = false }) {
    return axios.get(`/gateway/services/apiv2/v3/generate-report/?name=${reportName}&spirometry_session_ids=${spirometrySessionIds}&medications_field=medications_v2&site_id=${siteId}&format=${format}`, blob ? { responseType: 'blob' } : {});
  }

  getSpirometrySession (sessionID) {
    return axios.get(`/gateway/services/apiv2/spirometry_sessions/${sessionID}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Overwriting
  //------------------------------------------------------------------------------------------------------------

  getOverwriting (sessionID, overwritingID) {
    return axios.get(`/gateway/services/apiv2/spirometry/sessions/${sessionID}/overwritings/${overwritingID}`)
  }

  postOverwriting (overwriting) {
    return axios.post(`/gateway/services/apiv2/spirometry/sessions/${overwriting.sessionId}/overwritings/`, {
      occurred_at: new Date().toISOString(),
      created_by: overwriting.created_by,
      best_test: overwriting.bestTestId,
      comment: overwriting.comment ? overwriting.comment : null,
      excluded_test_ids: overwriting.excludedTestIds,
      fev1_selected_test: overwriting.fev1SelectedTestId ?? null, // null required by the backend, not undefined
      fvc_selected_test: overwriting.fvcSelectedTestId ?? null,
      pef_selected_test: overwriting.pefSelectedTestId ?? null
    });
  }

  deleteOverwriting (sessionId, overwritingId) {
    return axios.delete(`/gateway/services/apiv2/spirometry/sessions/${sessionId}/overwritings/${overwritingId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Asthma Report
  //------------------------------------------------------------------------------------------------------------
  /**
   * Post a single asthma report, sans id, to the backend to create a new report.
   *
   * @param asthmaReport
   * @returns {Promise<unknown>}
   */
  postAsthmaReport (asthmaReport) {
    if (asthmaReport.id) {
      throw new Error('/apipostAsthmaReport was given an id. Use patchAsthmaReport.');
    }

    return axios.post(`/gateway/services/apiv2/reports/`, asthmaReportSerialize(asthmaReport));
  }

  patchAsthmaReport (asthmaReport) {
    if (!asthmaReport.id) {
      throw new Error('patchAsthmaReport: id was undefined');
    }

    return axios.patch(`/gateway/services/apiv2/reports/${asthmaReport.id}`, asthmaReportPatchSerialize(asthmaReport));
  }

  getAsthmaReport (asthmaReportId) {
    return axios.get(`/gateway/services/apiv2/reports/${asthmaReportId}`);
  }

  getReportsByProfileId (profileId) {
    return axios.get(`/gateway/services/apiv2/reports/?profile_id=${profileId}&limit=100&offset=0&type=asthma`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Customer communication permissions
  //------------------------------------------------------------------------------------------------------------

  getCustomerCommunicationPreferences (profileID) {
    return axios.get(`/gateway/services/apiv2/profiles/${profileID}/settings/communications`);
  }

  putCustomerCommunicationPreferences (profileID, communicationPreferences) {
    return axios.put(`/gateway/services/apiv2/profiles/${profileID}/settings/communications`, communicationPreferences);
  }

  //------------------------------------------------------------------------------------------------------------
  // Goals
  //------------------------------------------------------------------------------------------------------------
  /**
   * Get/Post/Delete for patient goals
   *
   */
  getPatientGoalByProfileId (profileId) {
    return axios.get(`/gateway/services/patient-hub/goals/most-recent?profileId=${profileId}`);
  }

  postPatientGoal (profileId, goal) {
    return axios.post(`/gateway/services/patient-hub/goals?profileId=${profileId}`, {
      goal
    });
  }

  deletePatientGoal (goalId, profileId) {
    return axios.delete(`/gateway/services/patient-hub/goals/${goalId}?profileId=${profileId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Todos
  //------------------------------------------------------------------------------------------------------------
  async getTodosByProfileId (profileId) {
    const response = await axios.get(`/gateway/services/todos-service/api/todo-tasks?principal.equals=${profileId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Profile
  //------------------------------------------------------------------------------------------------------------
  getSiteProfileInfo (siteProfileId) {
    return axios.get(`/gateway/services/patient-hub/sites/profiles/${siteProfileId}`);
  }

  patchSiteProfile (profileId, changeObj) {
    return axios.patch(`/gateway/services/patient-hub/site/profiles/${profileId}`, changeObj);
  }

  addNewProfileAddress (profileId, newAddress) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/add-address`, newAddress);
  }

  deleteProfileAddress (profileId, addressId) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/remove-address?addressId=${addressId}`);
  }

  updateProfileAddress (profileId, updatedAddress) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/update-address`, updatedAddress);
  }

  addNewProfilePhone (profileId, newPhone) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/add-phone`, newPhone);
  }

  deleteProfilePhone (profileId, phoneId) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/remove-phone?phoneId=${phoneId}`)
  }

  updateProfilePhone (profileId, updatedPhone) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/update-phone`, updatedPhone);
  }

  getAllConditions () {
    return axios.get(`/gateway/services/patient-hub/conditions`);
  }

  addNewProfileConditions (profileId, newConditions) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/add-conditions`, newConditions);
  }

  deleteProfileConditions (profileId, conditionNames) {
    return axios.put(`/gateway/services/patient-hub/profiles/${profileId}/remove-conditions`, conditionNames);
  }

  getAssignedQuestionnaires (profileId) {
    return axios.get(`/gateway/services/questionnaire/api/v1/questionnaire-engine/assigned-questionnaires?principal=${profileId}`);
  }

  getQuestionnaireResults (profileId, questionnaireName) {
    return axios.get(`/gateway/services/questionnaire/api/v1/questionnaire-results/?principal.equals=${profileId}&questionnaireInternalName.equals=${questionnaireName}&size=1000`);
  }

  getAllQuestionnaireResults (profileId) {
    return axios.get(`/gateway/services/questionnaire/api/v1/questionnaire-results/?principal.equals=${profileId}&size=1000&sort=createdDate,desc`);
  }

  async getProfileLinks (ownerId) {
    const response = await axios.get(`/gateway/services/patient-hub/api/nuvoair-users/${ownerId}/links`);
    return response.data;
  }

  async getCommunicationMediums () {
    const response = await axios.get(`/gateway/services/patient-hub/communication-mediums`);
    return response.data;
  }

  async getPiiByPhoneNumber (phoneNumber) {
    const response = await axios.get(`/gateway/services/site-management/api/pii?profilePhone=${phoneNumber}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Filters
  //------------------------------------------------------------------------------------------------------------
  createFilterView (siteUserId, filterView) {
    return axios.put(`/gateway/services/patient-hub/site-users/${siteUserId}/add-filterview`, filterView);
  }

  updateFilterView (siteUserId, filterView) {
    return axios.put(`/gateway/services/patient-hub/site-users/${siteUserId}/update-filterview`, filterView);
  }
  getFilterViews (siteUserId) {
    return axios.get(`/gateway/services/patient-hub/site-users/${siteUserId}/filterviews`);
  }

  deleteFilterView (siteUserId, filterViewId) {
    return axios.put(`/gateway/services/patient-hub/site-users/${siteUserId}/remove-filterview?filterViewId=${filterViewId}`);
  }

  //------------------------------------------------------------------------------------------------------------
  // Communication Logs
  //------------------------------------------------------------------------------------------------------------
  async getCommunicationLogs ({ profileId, startDate, endDate }) {
    const queryParams = [];
    if (startDate) {
      queryParams.push(`sent.greaterThanOrEqual=${startDate}`);
    }
    if (endDate) {
      queryParams.push(`sent.lessThanOrEqual=${endDate}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await axios.get(`/gateway/services/patient-log/api/admin/communications?${queryParamsString}&page=0&sort=createdDate,desc&size=10000`);
    return response.data;
  }
  async getCommunicationLogById(logId) {
    const response = await axios.get(`/gateway/services/patient-log/api/admin/communications/${logId}`);
    return response.data;
  }
  async getCommunicationLogByCallId(callId) {
    const response = await axios.get(`/gateway/services/patient-log/api/communications/zoom-phone-log/${callId}`);
    return response.data;
  }
  async addCommunicationLog (profileId, communicationLog) {
    const response = await axios.post(`/gateway/services/patient-hub/communications?profileId=${profileId}`, communicationLog);
    return response.data;
  }
  async addCommunicationLogNote (communicationLogId, note) {
    const response = await axios.post(`/gateway/services/patient-log/api/communications/${communicationLogId}/add-note`, note);
    return response.data;
  }
  async updateCommunicationLog (communicationLog) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/communications/${communicationLog.id}`, communicationLog);
    return response.data;
  }
  async updateCommunicationLog (communicationLog) {
    const response = await this.PATCH(`/gateway/services/patient-log/api/admin/communications/${communicationLog.id}`, communicationLog);
    return response.data;
  }
  async updateCommunicationLogNote (noteId, note) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteCommunicationLogNote (noteId) {
    const response = await axios.delete(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }

  //------------------------------------------------------------------------------------------------------------
  // Appointments
  //------------------------------------------------------------------------------------------------------------

  async getAppointments (params) {
    const { profileId, startDate, endDate, page, filters } = params;
    const queryParams = [`page=${page || 0}`, `size=${filters.rows || 5}`];
    if (startDate) {
      queryParams.push(`startTime.greaterThanOrEqual=${startDate.toISOString()}`);
    }
    if (endDate) {
      queryParams.push(`startTime.lessThanOrEqual=${endDate.toISOString()}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }
    const response = await axios.get(`/gateway/services/patient-log/api/admin/appointments?${queryParamsString}&includePii=true`);
    return response;
  }
  async addAppointment (profileId, appointment) {
    const response = await axios.post(`/gateway/services/patient-hub/appointments?profileId=${profileId}&includePii=true`, appointment);
    return response.data;
  }
  async updateAppointment (appointmentId, appointment) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/appointments/${appointmentId}?includePii=true`, appointment);
    return response.data;
  }
  async addAppointmentNote (appointmentId, note) {
    const response = await axios.post(`/gateway/services/patient-log/api/appointments/${appointmentId}/add-note`, note);
    return response.data;
  }
  async updateAppointmentNote (noteId, note) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteAppointmentNote (noteId) {
    const response = await axios.delete(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }
  async addAppointmentToCareEvent (careEventId, appointment) {
    const response = await axios.post(`/gateway/services/patient-log/api/adverse-events/${careEventId}/add-appointment?includePii=true`, appointment);
    return response.data;
  }


  //------------------------------------------------------------------------------------------------------------
  // Care Events
  //------------------------------------------------------------------------------------------------------------
  async getAdverseEventTypes () {
    const response = await axios.get(`/gateway/services/patient-log/api/admin/adverse-event-types`);
    return response.data;
  }
  async getCareEvents (params) {
    const { profileId, startDate, endDate, actuality, page, rows } = params;
    const queryParams = [`page=${page || 0}`, `size=${rows || 5}`];

    if (startDate) {
      queryParams.push(`startTime.greaterThanOrEqual=${startDate.toISOString()}`);
    }
    if (endDate) {
      queryParams.push(`startTime.lessThanOrEqual=${endDate.toISOString()}`);
    }
    if (profileId) {
      queryParams.push(`principal.equals=${profileId}`);
    }
    if (actuality) {
      queryParams.push(`actuality.equals=${actuality}`);
    }

    appendFiltersToQueryParams(queryParams, params);

    let queryParamsString = queryParams.join('&');
    if (queryParamsString) {
      queryParamsString = `&${queryParamsString}`;
    }

    const response = await axios.get(`/gateway/services/patient-log/api/admin/adverse-events?${queryParamsString}&includePii=true`);
    return response;
  }
  async addCareEvent (profileId, careEvent) {
    const response = await axios.post(`/gateway/services/patient-hub/adverse-events?profileId=${profileId}&includePii=true`, careEvent);
    return response.data;
  }
  async updateCareEvent (careEventId, careEvent) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/adverse-events/${careEventId}?includePii=true`, careEvent);
    return response.data;
  }
  async addCareEventNote (careEventId, note) {
    const response = await axios.post(`/gateway/services/patient-log/api/adverse-events/${careEventId}/add-note`, note);
    return response.data;
  }
  async updateCareEventNote (noteId, note) {
    const response = await axios.patch(`/gateway/services/patient-log/api/admin/notes/${noteId}`, note);
    return response.data;
  }
  async deleteCareEventNote (noteId) {
    const response = await axios.delete(`/gateway/services/patient-log/api/admin/notes/${noteId}`);
    return response.data;
  }



  // ------------------------------------------------------------------------------------------------------------
  // Devices
  // ------------------------------------------------------------------------------------------------------------
  async getProfileDevices (profileId) {
    const response = await axios.get(`/gateway/services/device-manager/api/admin/device-owners?principal.equals=${profileId}`);
    return response.data;
  }
  async getAvailableDevices () {
    const response = await axios.get(`/gateway/services/device-manager/api/admin/devices?shippingCode.specified=true`);
    return response.data;
  }
  async cancelDeviceOrder (deviceOrderId) {
    const response = await axios.put(`/gateway/services/device-manager/orders/${deviceOrderId}/cancel-order`);
    return response.data;
  }
  async submitDeviceOrder (profileId, order) {
    const response = await axios.post(`/gateway/services/patient-hub/orders?profileId=${profileId}`, order);
    return response.data;
  }

  async getAppointmentScheduleLink (siteId) {
    const response = await axios.get(`/gateway/services/patient-log/api/v1/appointment-schedule/${siteId}`);
    return response.data;
  }

  async createAppointmentScheduleLink (siteId, appointmentScheduleLink) {
    const response = await axios.post(`/gateway/services/patient-log/api/v1/appointment-schedule`, {
      tenant: siteId,
      ...appointmentScheduleLink
    });
    return response.data;
  }

  async updateAppointmentScheduleLink (appointmentId, appointmentScheduleLink) {
    const response = await axios.put(`/gateway/services/patient-log/api/v1/appointment-schedule/${appointmentId}`, appointmentScheduleLink);
    return response.data;
  }

  async deleteAppointmentScheduleLink (appointmentId) {
    const response = await axios.delete(`/gateway/services/patient-log/api/v1/appointment-schedule/${appointmentId}`);
    return response.data;
  }
};



function appendFiltersToQueryParams (queryParams, filtersObj) {
  const { sortField, sortOrder, multiSortMeta, filters } = filtersObj;

  // Sorting
  if (sortField) {
    queryParams.push(`sort=${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`);
  }

  // Multi-sort
  if (multiSortMeta && multiSortMeta.length > 0) {
    multiSortMeta.forEach(sortMeta => {
      queryParams.push(`sort=${sortMeta.field},${sortMeta.order === 1 ? 'asc' : 'desc'}`);
    });
  }

  // Filters
  if (filters) {
    Object.keys(filters).forEach(key => {
      const filter = filters[key];

      // Check if the filter uses constraints
      if (filter.constraints && filter.constraints.length > 0) {
        // Extract all values for 'EQUALS' matchMode
        const equalValues = filter.constraints
          .filter(constraint => constraint.matchMode === FilterMatchMode.EQUALS && constraint.value)
          .map(constraint => constraint.value);

        // Concatenate these values and append as a single query parameter
        if (equalValues.length > 0) {
          queryParams.push(`${key}.in=${equalValues.join(',')}`);
        }

      } else if (filter.value) {
        // Handling single value filters (non-constraint based)
        queryParams.push(`${key}.equals=${filter.value}`);
      }
    });
  }


}
