<template>
    <div>
        <Button :class="{ 'p-button-sm': prefill }" label="Log Activity" icon="pi pi-plus" @click="showModal"></Button>

        <LogActivityDialog :showLogActivityModal="showLogActivityModal" pastActivity :prefill="prefill"
            :logToEdit="logToEdit" @save="addLog" v-on="inputListeners" @show="showModal" @hide="hideModal"
            :disabledFields="disabledFields">
        </LogActivityDialog>
    </div>
</template>
<script>
import LogActivityDialog from '@/components/communication/LogActivityDialog.vue'
import { createNamespacedHelpers } from 'vuex'
const communicationLogsVuex = createNamespacedHelpers('communicationLogs')
const profileVuex = createNamespacedHelpers('profile')
export default {
    components: {
        LogActivityDialog
    },
    props: {
        showLogActivityModal: {
            type: Boolean,
            default: false
        },
        logToEdit: {
            type: Object,
            default: null
        },
        prefill: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...profileVuex.mapGetters(['profileId']),
        inputListeners: function () {
            var vm = this
            return Object.assign({},
                this.$listeners,
                {
                    input: function (event) {
                        vm.$emit('input', event.target.value)
                    }
                }
            )
        },
        disabledFields () {
            return this.prefill ? { logDate: true, medium: true, purpose: true } : {}
        }
    },
    methods: {
        ...profileVuex.mapActions(['setFocus']),
        ...communicationLogsVuex.mapActions(['createLog']),
        async addLog (log) {
            try {
                const newLog = await this.createLog({ profileId: this.profileId, logData: log })
                if (this.prefill) {
                    this.setFocus({ sectionId: 'communication-logs-table', item: newLog })
                }
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Log created', life: 3000 });
                this.hideModal()
            }
            catch (e) {
                this.$toast.add({ severity: 'error', summary: 'Something went wrong', detail: `Cannot create log. ${e.message}`, life: 3000 });
            }
        },
        showModal () {
            this.$emit('update:showLogActivityModal', true)
        },
        hideModal () {
            this.$emit('update:showLogActivityModal', false)
        }
    }
}
</script>
<style lang="scss"></style>