export const SIDEBAR_TAGS = {
  ZOOM: 'zoom',
  COMMUNICATION_LOG: 'communication-log',
  ZOOM_TRANSCRIPT: 'zoom-transcript',
  SMS: 'sms',
}

export const SIDEBAR_COMPONENTS = {
  [SIDEBAR_TAGS.ZOOM]: 'zoom-sidebar',
  [SIDEBAR_TAGS.COMMUNICATION_LOG]: 'communication-log-sidebar',
  [SIDEBAR_TAGS.ZOOM_TRANSCRIPT]: 'zoom-transcript-sidebar',
  [SIDEBAR_TAGS.SMS]: 'sms-sidebar',
}

export const PERSISTENT_SIDEBARS = [SIDEBAR_TAGS.ZOOM];
