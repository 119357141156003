import { App } from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

export default {
  install: (app: App, options: any) => {
    app.config.globalProperties.$am4core = am4core;
    app.config.globalProperties.$am4charts = am4charts;
    am4core.addLicense(options.AMCHARTS_KEY);
  }
};
