import { slugFromSite } from "@/utils/modelUtil";

export function processSiteLoaded(site) {
  site.slug = slugFromSite(site);
  if ((typeof site.feature_values.spirometry_value_thresholds) === 'string') {
    site.feature_values.spirometry_value_thresholds = JSON.parse(site.feature_values.spirometry_value_thresholds);
  }
  return site;
}

export function buildSiteProfilesFilters(site, profileAggregates) {
  return []
  
  // TEMPORARILY REMOVED THE FILTERS
  // [
  //   {
  //     label: "Asthma",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return (profileAggregate.profile.diagnoses || []).indexOf("Asthma") !== -1;
  //     }
  //   },
  //   {
  //     label: "COPD",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return (profileAggregate.profile.diagnoses || []).indexOf("COPD") !== -1;
  //     }
  //   },
  //   {
  //     label: "CF",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return (profileAggregate.profile.diagnoses || []).indexOf("CF") !== -1;
  //     }
  //   },
  //   {
  //     label: "Grading F",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return profileAggregate.spirometry.last_spirometry_session &&
  //         profileAggregate.spirometry.last_spirometry_session.grading === "F";
  //     }
  //   },
  //   {
  //     label: "Grading A",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return profileAggregate.spirometry.last_spirometry_session &&
  //         profileAggregate.spirometry.last_spirometry_session.grading === "A";
  //     }
  //   },
  //   {
  //     label: "FVC < 80% of Best",
  //     active: false,
  //     type: Boolean,
  //     filter: profileAggregate => {
  //       return profileAggregate.spirometry.last_spirometry_session &&
  //         profileAggregate.spirometry.last_spirometry_session.fvc_percent_predicted < 80;
  //     }
  //   }
  // ];
}
