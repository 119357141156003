<template>
  <div>
    <DataTable class="events-datatable" :value="careEventRows" paginator lazy :totalRecords="totalRecords"
      @lazy-load="onLazyLoad" @page="onPageChange" @sort="onSortChange" @filter="onFilterChange" :rows="lazyParams.rows"
      sortField="startTime" :sortOrder="lazyParams.sortOrder" v-model:expandedRows="expandedRows" dataKey="id"
      responsiveLayout="scroll" collapsedRowIcon="pi pi-chevron-down" expandedRowIcon="pi pi-chevron-up"
      :loading="loading" v-model:filters="filters" filterDisplay="menu">
      <template #empty>
        {{ tableEmptyText }}
      </template>
      <template #loading>
        Loading records, please wait...
      </template>
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <div class="flex flex-column">
            <div class="flex align-items-center">
              <h3>{{ tableTitle }}</h3>
              <span class="ml-1 text-sm text-600">(Last {{ selectedFetchDaysInText }} displayed)</span>
            </div>
            <span class="text-sm text-600" :key="lastUpdateDateKey">
              last updated: {{ lastUpdatedDateFromNow }}
            </span>
          </div>
          <div class="flex justify-content-end align-items-center">
            <Button v-if="!consolidateSites && isConfirmedView" class="ml-2" label="Add Event" icon="pi pi-plus"
              @click="showEventActivityModal = true" />
            <Button v-if="showDatePickerFilter" icon="pi pi-calendar" aria:haspopup="true" aria-controls="datePickerPanel"
              class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2" @click="toggleDatePickerPanel" />
            <OverlayPanel ref="datePickerPanel" appendTo="body" id="datePickerPanel">
              <SelectButton v-model="selectedFetchDays" :options="fetchDaysOptions" optionLabel="label"
                optionValue="value" />
            </OverlayPanel>
            <Button icon="pi pi-replay" class="p-button-rounded p-button-secondary p-button-text p-button-sm ml-2"
              @click="fetchEvents" />
          </div>
        </div>
      </template>
      <Column field="patientName" header="Patient" sortable v-if="consolidateSites">
        <template #body="slotProps">
          <router-link v-if="slotProps.data.patientUrl" :to="slotProps.data.patientUrl">{{ slotProps.data.patientName
          }}</router-link>
          <span v-else>{{ slotProps.data.patientName }}</span>
        </template>
      </Column>
      <Column field="startTime" header="Start date" sortable>
        <template #body="slotProps">
          <div v-html="formatDate(slotProps.data.startTime)"></div>
        </template>
      </Column>
      <Column header="Type" sortable>
        <template #body="slotProps">
          <span>{{ slotProps.data.type.name }}</span>
        </template>
      </Column>
      <Column header="Alarm" sortable>
        <template #body="slotProps">
          <span>{{ firstAlarmDescription(slotProps.data) }}</span>
        </template>
      </Column>
      <Column field="tenant" header="Site" sortable v-if="consolidateSites" :showFilterMatchModes="false">
        <template #body="slotProps">
          <router-link v-if="slotProps.data.siteUrl" :to="slotProps.data.siteUrl">{{ slotProps.data.tenant
          }}</router-link>
          <span v-else>{{ slotProps.data.tenant }}</span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown v-model="filterModel.value" :options="sites" placeholder="Any" optionValue="id"
            optionLabel="long_name" class="p-column-filter">
            <template #option="slotProps">
              <span>{{ slotProps.option.long_name }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="endTime" header="End date" sortable v-if="!consolidateSites || isConfirmedView">
        <template #body="slotProps">
          <div v-if="slotProps.data.endTime" v-html="formatDate(slotProps.data.endTime)"></div>
          <div v-else>-</div>
        </template>
      </Column>
      <Column field="source" header="Source" sortable>
        <template #body="slotProps">
          <span>{{ renderSourceText(slotProps.data.source) }}</span>
        </template>
      </Column>
      <Column field="createdBy" header="Entry by" sortable>
        <template #body="slotProps">
          <span>{{ slotProps.data.createdByPii.lastName }}</span>
        </template>
      </Column>
      <Column field="Details" header="Details" v-if="!consolidateSites || isConfirmedView">
        <template #body="slotProps">
          <Tag v-if="slotProps.data.treatmentPlan" value="TP" severity="warning"></Tag>
          <Tag v-if="slotProps.data.newPrescription" value="+Rx" severity="success"></Tag>
          <Tag v-if="slotProps.data.hospitalization" value="Hosp" severity="info"></Tag>
          <Tag v-if="slotProps.data.erUtilization" value="ER" severity="danger"></Tag>
          <Tag v-if="slotProps.data.outPatientVisit" value="Out-Pt" severity="danger"></Tag>
          <Tag v-if="slotProps.data.nuvoAirVisit" value="NA Visit" severity="info"></Tag>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <span v-if="slotProps.data.notes && slotProps.data.notes.length > 0" class="pi pi-align-left"></span>
        </template>
      </Column>
      <Column :expander="true" :headerStyle="{ width: '5rem' }" />
      <template #expansion="slotProps">
        <EventDetails :event="slotProps.data" @edit-event="openEditForm" @confirm-event="confirmEvent"
          @dismiss-event="dismissEvent" @add-note-to-event="onNoteAdd" @update-note-on-event="onNoteUpdate"
          @delete-note-on-event="onNoteDelete" />
      </template>
    </DataTable>

    <EventActivityForm v-model:showEventActivityModal="showEventActivityModal" :eventToEdit="eventToEdit"
      :eventTypeOptions="eventTypeOptions" @save="addEvent" @update="updateEvent" @hide="onEventFormHidden"
      @show="showEventActivityModal = true">
    </EventActivityForm>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { getNameFromPii } from '@/helpers';

import EventActivityForm from './EventActivityForm.vue';
import EventDetails from './EventDetails.vue';
import { sourceList } from './constants.js';
import { formatDate, formatDateTime, addDays } from "@/utils/dateUtil";
import { slugFromSite } from "@/utils/modelUtil";
import { useCareEvents } from '@/composables/careEvents';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
  consolidateSites: {
    type: Boolean,
    default: false
  },
  selectedView: {
    type: String,
    default: 'potential',
    required: true
  }
});
const toast = useToast();

const store = useStore();
const {
  loading, events, lastUpdatedDate, eventTypeOptions,
  fetchAdverseEventTypes, fetchCareEvents, createCareEvent, updateCareEvent,
  addNoteToEvent, updateNoteOnEvent, deleteNoteOnEvent, lastUpdatedDateFromNow, totalRecords,
  setFetchParams
} = useCareEvents();

const filters = ref({
  tenant: { value: null, matchMode: FilterMatchMode.EQUALS },
});
const expandedRows = ref([]);
const showEventActivityModal = ref(false);
const eventToEdit = ref(null);
const selectedFetchDays = ref(0);
const lazyParams = ref({
  filters: filters.value,
  rows: props.consolidateSites ? 5 : 10,
  sortField: 'startTime',
  sortOrder: 1,
});
const interval = ref(null);
const lastUpdateDateKey = ref(0); // Used for forcing updates

const fetchDaysOptions = ref([ // Example: options for fetching days
  { label: '3 months', value: 90 },
  { label: '1 month', value: 30 },
  { label: '2 weeks', value: 14 },
  { label: '5 days', value: 5 },
  { label: '3 days', value: 3 },
  { label: '1 day', value: 1 },
]);

// Replace createNamespacedHelpers with direct store access
const sites = computed(() => store.state.portal.sites);
const getSiteByShortName = (shortName) => store.getters['portal/getSiteByShortName'](shortName);
const profileId = computed(() => store.getters['profile/profileId']);

onMounted(async () => {
  await fetchAdverseEventTypes();
  setupAutoRefresh();
});

onBeforeUnmount(() => {
  tearDownAutoRefresh();
});

// Watcher for selectedFetchDays
watch(selectedFetchDays, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    fetchEvents();
  }
});

const selectedView = computed(() => {
  return props.selectedView;
});

// Watcher for selectedView
watch(selectedView, (newVal, oldVal) => {
  const targetDays = (newVal === 'actual' && props.consolidateSites) ? 90 : 90;

  if (selectedFetchDays.value !== targetDays) {
    selectedFetchDays.value = targetDays;
  } else {
    fetchEvents();
  }
}, { immediate: true });



const careEventRows = computed(() => {
  return events.value?.map(e => {
    const site = getSiteByShortName(e.tenantsPii[0]?.shortName);
    const slug = site ? slugFromSite(site) : '';
    const patientName = getNameFromPii(e.principalPii);
    const siteProfileId = getSiteProfileIdByEvent(e);
    return {
      ...e,
      patientName: patientName,
      tenant: e.tenantsPii[0].longName,
      siteUrl: slug ? `/site/${slug}` : null,
      createdBy: e.createdByPii.lastName,
      patientUrl: slug && siteProfileId ? `/site/${slug}/patient/${siteProfileId}` : null
    };
  });
});

const isConfirmedView = computed(() => {
  return props.selectedView === 'actual';
});

const showDatePickerFilter = computed(() => {
  return !props.consolidateSites || (props.consolidateSites && !isConfirmedView.value);
});

const selectedFetchDaysInText = computed(() => {
  const option = fetchDaysOptions.value.find(o => o.value === selectedFetchDays.value);
  return option ? option.label : '';
});

const tableTitle = computed(() => {
  return isConfirmedView.value ? 'Addressed Events' : 'Unaddressed Events';
});

const tableEmptyText = computed(() => {
  return isConfirmedView.value ? 'No addressed events' : 'No unaddressed events';
});

const firstAlarmDescription = computed(() => {
  return (data) => {
    if (!data || !Array.isArray(data.alarms) || data.alarms.length === 0) {
      return '-'
    }
    return data.alarms[0].description || '-'
  }
})

// Define methods
const setupAutoRefresh = () => {
  if (props.consolidateSites) {
    interval.value = setInterval(() => {
      lastUpdatedDate.value = moment(lastUpdatedDate.value).add(1, 'seconds');
      lastUpdateDateKey.value += 1;
    }, 3000);
  }
};

const tearDownAutoRefresh = () => {
  clearInterval(interval.value);
};

const toggleDatePickerPanel = (event) => {
  $refs.datePickerPanel.toggle(event);
};

const fetchEvents = async () => {
  console.log('fetchEvents')
  try {
    const startDate = moment().startOf('day').subtract(selectedFetchDays.value, 'days').toDate();
    const endDate = moment().endOf('day').toDate();

    const params = {
      ...lazyParams.value,
      startDate: startDate,
      endDate: endDate,
      actuality: isConfirmedView.value ? 'ACTUAL' : 'POTENTIAL'
    };

    if (!props.consolidateSites) {
      params.profileId = profileId.value;
    }

    setFetchParams(params);
    await fetchCareEvents(params);
  } catch (e) {
    console.error(e);
  }
};

const onLazyLoad = (event) => {
  lazyParams.value.page = event.page;
  fetchEvents();
};

const onFilterChange = (event) => {
  lazyParams.value.filters = event.filters;
  fetchEvents();
};

const onPageChange = (event) => {
  lazyParams.value.page = event.page;
  fetchEvents();
};

const onSortChange = (event) => {
  lazyParams.value.sortField = event.sortField;
  lazyParams.value.sortOrder = event.sortOrder;
  fetchEvents();
};

const onEventFormHidden = () => {
  showEventActivityModal.value = false
  eventToEdit.value = null
}

const openEditForm = (eventId) => {
  showEventActivityModal.value = true;
  eventToEdit.value = events.value.find(e => e.id === eventId);
};

const addEvent = async (eventToAdd) => {
  try {
    await createCareEvent({ profileId: profileId.value, eventData: eventToAdd })
    showEventActivityModal.value = false
    toast.add({ severity: 'success', summary: 'Success', detail: 'Care event created', life: 3000 });
  }
  catch (e) {
    console.log(e)
    toast.add({ severity: 'error', summary: 'Something went wrong', detail: `Cannot create event. ${e.message}`, life: 3000 });
  }
}

const updateEvent = async (payload) => {
  try {
    await updateCareEvent(payload)
    showEventActivityModal.value = false
    toast.add({ severity: 'success', summary: 'Success', detail: 'Care event updated', life: 3000 });
  }
  catch (e) {
    console.log(e)
    toast.add({ severity: 'error', summary: 'Something went wrong', detail: `Cannot update event. ${e.message}`, life: 3000 });
  }
}

const confirmEvent = async (eventId) => {
  try {
    await updateCareEvent({ eventId, eventData: { actuality: 'ACTUAL' } })
    switchToActualView()
    toast.add({ severity: 'success', summary: 'Success', detail: 'Care event confirmed', life: 3000 });
  }
  catch (e) {
    console.log(e)
  }
}

const dismissEvent = async (eventId) => {
  try {
    const event = events.value.find(e => e.id === eventId)
    await updateCareEvent({ eventId, eventData: { endTime: addDays(new Date(event.startTime), -30), actuality: 'CANCELLED' } })
    toast.add({ severity: 'success', summary: 'Success', detail: 'Care event dismissed', life: 3000 });
  }
  catch (e) {
    console.log(e)
  }
}

const getSiteProfileIdByEvent = (event) => {
  return event.principalPii?.siteProfileIds?.[0] || null
}

const renderSourceText = (source) => {
  return sourceList.find(p => p.key === source)?.label
}
</script> 


<style lang="scss" scoped>
.events-datatable {
  margin-bottom: 24px;
  border-radius: 16px;
}

p {
  margin: 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.table-header-container {
  display: flex;
  justify-content: flex-end;
}
</style>