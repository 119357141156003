<template>
  <select-data-loader load-sites>
    <div v-if="sites">
      <TabView v-if="isUSSite">
        <TabPanel header="Dashboard">
          <Dashboard />
        </TabPanel>
        <TabPanel header="Sites">
          <SitesList />
        </TabPanel>
      </TabView>
      <SitesList v-else />
    </div>
  </select-data-loader>
</template>

<script>
import SelectDataLoader from "@/components/loaders/SelectDataLoader.vue";
import PortalPageMixin from "../mixins/PortalPageMixin";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import SitesList from "@/components/sitesList/SitesList.vue";
import { getTLDFromHostname } from '@/helpers';
import "./index.scss";

export default {
  middleware: ["auth"],
  mixins: [PortalPageMixin],
  name: "route-home",
  components: {
    SelectDataLoader,
    Dashboard,
    SitesList
  },
  computed: {
    isUSSite () {
      return getTLDFromHostname().toLowerCase() === 'us' || this.$config.ENV_NAME === 'local';
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .p-tabview .p-tabview-panels {
  background: #ECF0F3;
}
</style>
