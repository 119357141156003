import { createApp } from "vue";
import App from "@/App.vue";
import { store } from "@/store";
import config from '@/config'
import '@/styles/fonts.scss';
import router from '@/router'
import '@/styles/base.scss';
import '/node_modules/primeflex/primeflex.css';


// Plugins
import PrimeVuePlugin from './plugins/prime';
import VuetifyPlugin from '@/plugins/vuetify';
import AgGridPlugin from '@/plugins/aggrid';
import AmChartsPlugin from '@/plugins/amcharts';
import DatadogPlugin from '@/plugins/datadog';
import apiv2 from '@/api';

const app = createApp(App);

app.use(router);
app.use(store);

// Setup plugins
app.use(PrimeVuePlugin);
app.use(VuetifyPlugin);
app.use(AgGridPlugin, { AGGRID_KEY: config.AGGRID_KEY });
app.use(AmChartsPlugin, { AMCHARTS_KEY: config.AMCHARTS_KEY });
app.config.globalProperties.$apiv2 = apiv2
app.use(DatadogPlugin, {
    DATADOG_ENABLED: config.DATADOG_ENABLED,
    DATADOG_SITE_URL: config.DATADOG_SITE_URL,
    DATADOG_CLIENT_TOKEN: config.DATADOG_CLIENT_TOKEN,
    ENV_NAME: config.ENV_NAME,
    RELEASE_TAG: config.RELEASE_TAG
});

app.mount("#app");




// import { ViteSSG } from 'vite-ssg'
// import { setupLayouts } from 'virtual:generated-layouts'
// // import Previewer from 'virtual:vue-component-preview'
// import App from '@/App.vue'
// import type { UserModule } from '@/types'
// import generatedRoutes from '~pages'

// import '@unocss/reset/tailwind.css'
// import '@/styles/main.css'
// import 'uno.css'

// const routes = setupLayouts(generatedRoutes)

// import { createRouter, createWebHistory } from 'vue-router'
// import routes from 'virtual:generated-pages'
// import App from '@/App.vue'

// import '@unocss/reset/tailwind.css'
// import '@/styles/main.css'
// import 'uno.css'

// const app = createApp(App)
// const router = createRouter({
//     history: createWebHistory(import.meta.env.BASE_URL),
//     routes,
// })
// app.use(router)