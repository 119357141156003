<template>
  <div>
    <div class="flex justify-content-between">
      <div>
        <Button v-if="isEventPotential" label="Dismiss" icon="pi pi-times" class="p-button-danger p-button-sm"
          @click="dismissEvent" />
      </div>
      <div class="flex justify-content-end align-items-center">
        <Button label="Update" icon="pi pi-pencil" class="p-button-sm p-button-outlined" @click="editEvent" />
        <AppointmentForm class="ml-2" v-model:showLogActivityModal="showLogActivityModal" :careEventId="eventId"
          :appointmentToEdit="appointmentToEdit" :siteId="eventSiteId">
        </AppointmentForm>
        <Button v-if="isEventPotential" label="Confirm" icon="pi pi-check-circle"
          class="p-button-success p-button-sm ml-2" @click="confirmEvent" />
      </div>
    </div>
    <LogNotes :logId="eventId" :notes="event.notes" @add-note="onNoteAdd" @update-note="onNoteUpdate"
      @delete-note="onNoteDelete" />
  </div>
</template>
<script>
import LogNotes from '../common/LogNotes.vue'
import AppointmentForm from '@/components/communication/appointments/AppointmentForm.vue'
import moment from 'moment'

export default {
  components: {
    LogNotes,
    AppointmentForm
  },
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
  },
  data () {
    return {
      showLogActivityModal: false,
      appointmentToEdit: null,
    }
  },
  computed: {
    isEventPotential () {
      return this.event.actuality === 'POTENTIAL'
    },
    eventId () {
      return this.event.id
    },
    eventSiteId () {
      return this.event.tenants[0]
    }
  },
  methods: {
    onNoteAdd (payload) {
      this.$emit('add-note-to-event', payload)
    },
    onNoteUpdate (payload) {
      this.$emit('update-note-on-event', payload)
    },
    onNoteDelete (payload) {
      this.$emit('delete-note-on-event', payload)
    },
    editEvent () {
      this.$emit('edit-event', this.eventId)
    },
    dismissEvent () {
      this.$emit('dismiss-event', this.eventId)
    },
    confirmEvent () {
      this.$emit('confirm-event', this.eventId)
    }
  },
  watch: {
    showLogActivityModal (val) {
      if (val) {
        this.appointmentToEdit = {
          startTime: this.event.endTime || moment().add(1, 'day').toDate(),
          medium: 'VERBAL_PHONE',
          purpose: 'CARE_EVENT',
          notes: null,
        }
      }
      else {
        this.appointmentToEdit = null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.notes-wrapper {
  margin: 8px 0;
  background-color: #f7f7f7;
  border-radius: 4px;
}

.note-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  &__top {
    display: flex;

    &__notes {
      flex: 1;

      // TODO: to be removed after Vuetify is removed
      :deep ul {
        padding-inline-start: 40px;
      }
    }

    &__ctas {
      display: flex;
      justify-content: space-between;
      align-items: center;

      * {
        margin-left: 8px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 11px;
    color: #999;
  }
}
</style>