class AuthManager {
    private _isLoggedIn: boolean;

    constructor() {
        const token = localStorage.getItem("access_token");
        this._isLoggedIn = token ? true : false;
    }

    setLoggedIn(loggedIn: boolean, token?: string) {
        this._isLoggedIn = loggedIn;
        if (loggedIn && token) {
            localStorage.setItem("access_token", token);
        } else {
            localStorage.removeItem("access_token");
        }
    }

    get isLoggedIn() {
        return this._isLoggedIn;
    }

    checkSSOToken() {
        const ssoToken = localStorage.getItem("sso_token"); // or get from cookie
        if (ssoToken && ssoToken !== this.getToken()) {
            this.setLoggedIn(true, ssoToken);
            // Optionally dispatch an action to fetch user details
        }
    }

    getToken() {
        return localStorage.getItem("access_token");
    }
}

const authManager = new AuthManager();
export default authManager;