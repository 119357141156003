// prime.ts
import { App } from 'vue';
import config from "@/config";

import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';

// Conditional import of styles
if (config.PURE_PRIMEVUE) {
    import("./primevue-style-pure");
} else {
    import("./primevue-style-legacy");
}


// Import PrimeVue components
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import Editor from 'primevue/editor';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Badge from 'primevue/badge';
import Panel from 'primevue/panel';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';
import Chip from 'primevue/chip';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import Menubar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import TieredMenu from 'primevue/tieredmenu';
import Toolbar from 'primevue/toolbar';
import Menu from 'primevue/menu';
import Slider from 'primevue/slider';
import Listbox from 'primevue/listbox';
import Tag from 'primevue/tag';
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import AutoComplete from 'primevue/autocomplete';

export default {
    install: (app: App): void => {
        app.use(PrimeVue);

        // Register PrimeVue components globally
        app.component('InputText', InputText);
        app.component('Button', Button);
        app.component('DataTable', DataTable);
        app.component('Dialog', Dialog);
        app.component('Column', Column);
        app.component('Calendar', Calendar);
        app.component('RadioButton', RadioButton);
        app.component('Dropdown', Dropdown);
        app.component('Textarea', Textarea);
        app.component('OverlayPanel', OverlayPanel);
        app.component('Divider', Divider);
        app.component('Editor', Editor);
        app.component('Accordion', Accordion);
        app.component('AccordionTab', AccordionTab);
        app.component('Card', Card);
        app.component('Checkbox', Checkbox);
        app.component('InputNumber', InputNumber);
        app.component('Badge', Badge);
        app.component('Panel', Panel);
        app.component('InputSwitch', InputSwitch);
        app.component('SelectButton', SelectButton);
        app.component('Toast', Toast);
        app.component('ConfirmPopup', ConfirmPopup);
        app.component('ProgressSpinner', ProgressSpinner);
        app.component('Skeleton', Skeleton);
        app.component('Chip', Chip);
        app.component('Message', Message);
        app.component('Menubar', Menubar);
        app.component('Avatar', Avatar);
        app.component('TieredMenu', TieredMenu);
        app.component('Toolbar', Toolbar);
        app.component('Menu', Menu);
        app.component('InlineMessage', InlineMessage);
        app.component('Slider', Slider);
        app.component('AutoComplete', AutoComplete);
        app.component('Listbox', Listbox);
        app.component('Tag', Tag);
        app.component('TabPanel', TabPanel);
        app.component('TabView', TabView);

        app.use(ConfirmationService);
        app.use(ToastService);

        // Assuming that Tooltip is a directive in PrimeVue 3
        app.directive('tooltip', Tooltip);
    },
};
