<template>
    <div>
        <Button v-if="!hideActivator" :class="{ 'p-button-sm': prefill }" label="Create Appointment" icon="pi pi-plus"
            @click="showModal"></Button>

        <LogActivityDialog :showLogActivityModal="showLogActivityModal" :prefill="prefill" :logToEdit="appointmentToEdit"
            :siteId="siteIdToUse" @save="addAppointment" @update="saveAppointmentEdits" v-on="inputListeners"
            @show="showModal" @hide="hideModal">
        </LogActivityDialog>
    </div>
</template>
<script>
import LogActivityDialog from '../LogActivityDialog.vue'
import { createNamespacedHelpers } from 'vuex'
const appointmentsVuex = createNamespacedHelpers('appointments')
const profileVuex = createNamespacedHelpers('profile')
const portalVuex = createNamespacedHelpers('portal')
export default {
    components: {
        LogActivityDialog
    },
    props: {
        showLogActivityModal: {
            type: Boolean,
            default: false
        },
        hideActivator: {
            type: Boolean,
            default: false
        },
        appointmentToEdit: {
            type: Object,
            default: null
        },
        careEventId: {
            type: String,
            default: null
        },
        siteId: {
            type: String,
            required: false
        },
    },
    computed: {
        ...profileVuex.mapGetters(['profileId']),
        ...portalVuex.mapGetters(['currentSiteId']),
        prefill () {
            return !!this.careEventId
        },
        inputListeners: function () {
            var vm = this;
            return {
                ...this.$attrs,
                input: function (event) {
                    vm.$emit('input', event.target.value);
                }
            };
        },
        siteIdToUse () {
            return this.siteId || this.currentSiteId
        }
    },
    methods: {
        ...profileVuex.mapActions(['setFocus']),
        ...appointmentsVuex.mapActions(['createAppointment', 'createAppointmentToCareEvent', 'updateAppointment']),
        async addAppointment (appointment) {
            try {
                if (this.careEventId) {
                    const newAppointment = await this.createAppointmentToCareEvent({ careEventId: this.careEventId, appointmentData: appointment })
                    this.setFocus({ sectionId: 'appointments-table', item: newAppointment })
                }
                else {
                    await this.createAppointment({ profileId: this.profileId, appointmentData: appointment })
                }
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Appointment created', life: 3000 });
                this.hideModal()
            }
            catch (e) {
                console.log(e)
                this.$toast.add({ severity: 'error', summary: 'Something went wrong', detail: `Cannot create appointment. ${e.message}`, life: 3000 });
            }
        },
        async saveAppointmentEdits ({ logId, logData }) {
            try {
                await this.updateAppointment({ appointmentId: logId, appointmentData: logData })
                this.hideModal()
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Appointment updated', life: 3000 });
            }
            catch (e) {
                console.log(e)
                this.$toast.add({ severity: 'error', summary: 'Something went wrong', detail: `Cannot update appointment. ${e.message}`, life: 3000 });
            }
        },
        showModal () {
            this.$emit('update:showLogActivityModal', true)
        },
        hideModal () {
            this.$emit('update:showLogActivityModal', false)
        }
    }
}
</script>
<style lang="scss"></style>